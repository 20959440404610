import * as React from 'react';

import {
    ApplicationHelpType,
    ReportSettings_PlaintiffsFirmDisplayMode,
    ReportSettings_ReportCaseDetailsLastColumn,
    ReportSettings_ReportCaseDetailsLastColumnDisplayName,
    TimelineReportSections,
    WeeklyRecapReportSectionList,
} from '../../utilities/Constants';

import CheckmateSelect from '../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import { Help } from '../shared/Help';
import { ILookupModel } from '../../interfaces/ILookup';
import { ITimelineReportInputModel } from '../../interfaces/Report/ITimelineReport';
import { IValidation } from '../../interfaces/IError';
import { IWeeklyRecapState } from '../../pages/report/timeline/weekly-recap/WeeklyRecap';
import { Modal } from 'react-bootstrap';

export interface IReportSettingsModalProps {
    disabled?: boolean;
    inputModel: ITimelineReportInputModel;
    isReadOnlyReport: boolean;
    onHide: () => void;
    onSectionCheckedChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => void;
    onSubmit: () => void;
    open: boolean;
    reportGuid?: string;
    reportState: IWeeklyRecapState;
    setReportState: React.Dispatch<React.SetStateAction<IWeeklyRecapState>>;
    setValidation: React.Dispatch<React.SetStateAction<IValidation>>;
    validation?: IValidation;
}

export function ReportSettingsModal({
    inputModel,
    isReadOnlyReport,
    onHide,
    onSectionCheckedChange,
    onSubmit,
    open,
    disabled,
    reportGuid,
    reportState,
    setReportState,
    setValidation,
    validation,
}: IReportSettingsModalProps) {
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const localValidation = { ...validation };

        const { name, value } = event.target;

        switch (name) {
            case 'startDate':
                inputModel.startDate = value;
                break;
            case 'endDate':
                inputModel.endDate = value;
                break;
            case ReportSettings_ReportCaseDetailsLastColumnDisplayName:
                if (!inputModel.additionalSettings) inputModel.additionalSettings = {};
                inputModel.additionalSettings.reportCaseDetailsLastColumnDisplayName = value;
                if (value) localValidation.settings = [];
                break;
            default:
                break;
        }
        setValidation((prevState) => ({ ...prevState, ...localValidation }));
        setReportState((prevState) => ({
            ...prevState,
            inputModel,
        }));
    };

    const handleCheckmateSelectChange = (selectedItem: any, key: string) => {
        if (selectedItem === null || !selectedItem.id) return;

        if (!inputModel.additionalSettings) inputModel.additionalSettings = {};
        switch (key) {
            case ReportSettings_PlaintiffsFirmDisplayMode:
                inputModel.additionalSettings.plaintiffsFirmDisplayMode = selectedItem.id;
                setReportState((prevState: IWeeklyRecapState) => ({
                    ...prevState,
                    plaintiffsFirmDisplayMode: selectedItem,
                }));
                break;
            case ReportSettings_ReportCaseDetailsLastColumn:
                inputModel.additionalSettings.reportCaseDetailsLastColumn = selectedItem.id;
                inputModel.additionalSettings.reportCaseDetailsLastColumnDisplayName = '';
                setReportState((prevState: IWeeklyRecapState) => ({
                    ...prevState,
                    reportCaseDetailsLastColumn: selectedItem,
                }));
                break;
            default:
                break;
        }
    };

    return (
        <Modal
            centered
            size="lg"
            show={open}
            onHide={onHide}
            backdrop={false}
            dialogClassName="board-settings-dialog"
        >
            <Modal.Header>
                <Modal.Title>Report Settings</Modal.Title>
                <button className="btn btn-no-bg float-end" onClick={onHide}>
                    <i className="fal fa-xmark-large" />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <span className="text-danger">{validation?.settings}</span>
                </div>
                <div className="row mt-2">
                    <div className="col-sm-2">
                        <label className="control-label">Date Range</label>
                    </div>
                    <div className="col-sm-10">
                        <input
                            type="date"
                            name="startDate"
                            disabled={disabled || !!reportGuid}
                            className={
                                'form-control d-inline-block horizontal-margin' +
                                (inputModel &&
                                inputModel.startDate &&
                                Common.isValidDate(inputModel.startDate)
                                    ? ''
                                    : ' unselectClass')
                            }
                            value={inputModel && inputModel.startDate ? inputModel.startDate : ''}
                            onChange={handleChange}
                        />
                        <span className="horizontal-margin">to</span>
                        <input
                            type="date"
                            name="endDate"
                            disabled={disabled || !!reportGuid}
                            value={inputModel && inputModel.endDate ? inputModel.endDate : ''}
                            className={
                                'form-control d-inline-block horizontal-margin' +
                                (inputModel &&
                                inputModel.endDate &&
                                Common.isValidDate(inputModel.endDate)
                                    ? ''
                                    : ' unselectClass')
                            }
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row my-2">
                    <label className="control-label">Sections</label>
                </div>
                {WeeklyRecapReportSectionList.map((section: ILookupModel) => (
                    <SectionSettings
                        key={section.id}
                        section={section}
                        checked={
                            !!inputModel.reportSectionDetails?.some((x) => x.section === section.id)
                        }
                        onChange={onSectionCheckedChange}
                        disabled={disabled}
                    />
                ))}

                {inputModel.reportSectionDetails?.some(
                    (element) =>
                        element.section === TimelineReportSections.RiskIssues ||
                        element.section === TimelineReportSections.SignificantActivity ||
                        element.section === TimelineReportSections.UpcomingTrialDates ||
                        element.section === TimelineReportSections.TenderedCases
                ) && (
                    <>
                        <div className="row my-2">
                            <label className="control-label">
                                Case Details
                                <Help
                                    type={ApplicationHelpType.Info}
                                    title="Case Details"
                                    helpText="The settings under the Case Details applies to the following sections: Tendered Cases, Risk Issues, Significant Activity and Upcoming Trial Dates."
                                />
                            </label>
                        </div>
                        <>
                            <div className="row mt-1 ms-3">
                                <div className="col-sm-4">
                                    <label className="col-form-label text-gray">
                                        Plaintiffs Firm *
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <CheckmateSelect
                                        options={CheckmateSelectHelper.getPlaintiffsFirmDisplayOptions()}
                                        name={ReportSettings_PlaintiffsFirmDisplayMode}
                                        value={reportState.plaintiffsFirmDisplayMode}
                                        onChange={(e: any) => {
                                            handleCheckmateSelectChange(
                                                e,
                                                ReportSettings_PlaintiffsFirmDisplayMode
                                            );
                                        }}
                                        isDisabled={disabled}
                                    />
                                </div>
                            </div>
                            <div className="row mt-1 ms-3">
                                <div className="col-sm-4">
                                    <label className="col-form-label text-gray">
                                        Last Column *
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <CheckmateSelect
                                        options={CheckmateSelectHelper.getAdditionalCaseIdentifierOptions()}
                                        name={ReportSettings_ReportCaseDetailsLastColumn}
                                        value={reportState.reportCaseDetailsLastColumn}
                                        onChange={(e: any) => {
                                            handleCheckmateSelectChange(
                                                e,
                                                ReportSettings_ReportCaseDetailsLastColumn
                                            );
                                        }}
                                        isDisabled={disabled}
                                    />
                                </div>
                            </div>
                            <div className="row mt-1 ms-3">
                                <div className="col-sm-4">
                                    <label className="col-form-label text-gray">
                                        Last Column Header Name *
                                    </label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name={ReportSettings_ReportCaseDetailsLastColumnDisplayName}
                                        placeholder="Header Name"
                                        value={
                                            inputModel.additionalSettings
                                                ? inputModel.additionalSettings
                                                      .reportCaseDetailsLastColumnDisplayName
                                                : ''
                                        }
                                        onChange={handleChange}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {reportGuid ? (
                    <>
                        {!disabled && !isReadOnlyReport ? (
                            <>
                                <button className="btn btn-orange text-gray" onClick={onSubmit}>
                                    Run Report
                                </button>
                            </>
                        ) : (
                            <button className="btn btn-default" onClick={onHide}>
                                Close
                            </button>
                        )}
                    </>
                ) : (
                    <>
                        <button
                            className="btn btn-default"
                            onClick={() =>
                                setReportState((prevState: IWeeklyRecapState) => ({
                                    ...prevState,
                                    showClearSettingssConfirmModal: true,
                                }))
                            }
                        >
                            Clear
                        </button>
                        <button className="btn btn-orange text-gray" onClick={onSubmit}>
                            Run Report
                        </button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
}

function SectionSettings({
    disabled,
    section,
    checked,
    onChange,
}: {
    disabled?: boolean;
    section: ILookupModel;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, section: TimelineReportSections) => void;
}) {
    return (
        <div className="mt-2">
            <label>
                <input
                    id={'chkSection_' + section.id}
                    className="form-check-input d-inline-block mt-1"
                    type="checkbox"
                    checked={checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(e, section.id);
                    }}
                    disabled={disabled}
                />
                <span className="ms-2 align-top">{section.displayName}</span>
            </label>
        </div>
    );
}
