import * as React from 'react';

import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { HttpResponseError } from '../../interfaces/IHttpResponseError';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { TriggerRulesTable } from '../../components/triggers/trigger-rules-table/TriggerRulesTable';
import { cloneDeep } from 'lodash';
import { useGetTriggerRules } from '../../components/triggers/trigger-rules-table/useGetTriggerRules';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export interface ITriggersPageProps {
    user: IUserModel;
}

export function TriggersPage(props: ITriggersPageProps) {
    const navigate = useNavigate();
    const [validation, setValidation] = React.useState<IValidation>({});

    const handleError = (error: HttpResponseError) => {
        if (error.status === 403) {
            navigate(LocalRoutes.AccessDenied);
        }
    };

    const triggerRulesApi = useGetTriggerRules(true, handleError);
    const [searchParams] = useSearchParams();

    const handleAddClick = () => {
        navigate(LocalRoutes.TriggerNew);
    };

    const isProdEnv = window.location.host.indexOf('app.checkmate.legal') > -1;
    const isTestMode = searchParams.get('test');
    const allowDisableTrigger = !!isTestMode && !isProdEnv;

    return (
        <>
            <TriggersPageHeader onAddClick={handleAddClick} />
            <div className="row">
                <span className="text-danger">{validation.forbidden}</span>
                <span className="text-danger">{validation.delete}</span>
            </div>
            {!triggerRulesApi.error && (
                <TriggerRulesTable
                    user={props.user}
                    settings={triggerRulesApi.data ?? []}
                    onDeleteSuccess={() => {
                        setValidation((prev) => {
                            const copy = cloneDeep(prev);
                            delete copy.delete;
                            return copy;
                        });
                        triggerRulesApi.refetch();
                    }}
                    onDeleteError={(errorMessage) => {
                        if (typeof errorMessage === 'string') {
                            setValidation((prev) => ({ ...prev, delete: [errorMessage] }));
                        }
                        triggerRulesApi.refetch();
                    }}
                    allowDisable={allowDisableTrigger}
                />
            )}
        </>
    );
}

interface IPageHeaderProps {
    onAddClick: () => void;
}

export function TriggersPageHeader(props: IPageHeaderProps) {
    return (
        <>
            <DocumentTitle title="Trigger Rules" />
            <div className="row mb-3">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <h1 className="col-sm-4">Trigger Rules</h1>
                    <button className="btn-no-bg" onClick={props.onAddClick}>
                        <span className="btn-green btn btn-icon">
                            <i className="fal fa-lg fa-plus color-white" />
                        </span>
                    </button>
                </div>
            </div>
        </>
    );
}
