import * as React from 'react';

import { ICaseModel, ICaseSearchModel } from '../../interfaces/ICase';
import { SortColumns, SortDirections } from '../../utilities/Constants';

import ApiClient from '../../services/apiClient';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { Modal } from 'react-bootstrap';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();
const PageSize = 10;

interface IBasicCaseSearchProps {
    handleCaseSelected: (caseItem: ICaseModel) => void;
    showCaseSearch: boolean;
    onCancel: () => void;
}

interface IBasicCaseSearchState {
    showCaseSearch: boolean;
    pendingResponse: boolean;
    caseSearchString: string;
    caseSearchResults: ICaseModel[];
    validation: IValidation;
    currentPageNumber: number;
    totalCaseCount: number;
}

export class BasicCaseSearch extends React.Component<IBasicCaseSearchProps, IBasicCaseSearchState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showCaseSearch: false,
            pendingResponse: false,
            caseSearchString: '',
            caseSearchResults: [],
            validation: {},
            currentPageNumber: 0,
            totalCaseCount: 0,
        };
    }

    componentDidMount() {
        this.setState({ showCaseSearch: this.props.showCaseSearch });
    }

    componentDidUpdate(prevProps: IBasicCaseSearchProps) {
        if (prevProps.showCaseSearch != this.props.showCaseSearch)
            this.setState({ showCaseSearch: this.props.showCaseSearch });
    }

    refreshCaseCount = async () => {
        this.setState({ pendingResponse: true });
        const res = await _apiClient.getCaseCount({ searchText: this.state.caseSearchString });
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (res.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (res.payload) {
            this.setState({ totalCaseCount: res.payload.data, pendingResponse: false });
        }
    };

    runSearch = async (newSearch?: boolean) => {
        const validation: IValidation = {};

        if (newSearch) {
            if (!this.state.caseSearchString || this.state.caseSearchString.trim() == '') {
                validation.caseNameOrNumberSearch = ['Enter a Case Number or Case Name. '];
                this.setState({ validation: validation });
                return;
            } else {
                validation.caseNameOrNumberSearch = [''];
            }

            await this.refreshCaseCount();
        }

        this.setState({ pendingResponse: true });

        const caseSearchParameters: ICaseSearchModel = {
            searchText: this.state.caseSearchString,
            queryModel: {
                pageNumber: this.state.currentPageNumber,
                pageSize: PageSize,
                sortDirection: SortDirections.Asc,
                sortBy: SortColumns.CaseName,
            },
        };

        const res = await _apiClient.getCases(caseSearchParameters);
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return false;
        }
        if (res.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                pendingResponse: false,
            });
            return false;
        }

        this.setState({
            caseSearchResults: res.payload!,
            validation: validation,
            pendingResponse: false,
        });
        return true;
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;
        return (
            <div>
                {this.state.showCaseSearch ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() => {
                            this.setState({ showCaseSearch: false });
                        }}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Case Search</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="no-padding case-search-div">
                                        <input
                                            className="form-control float-end"
                                            type="text"
                                            name="caseNameOrNumberSearch"
                                            placeholder="Search by Name or Number"
                                            value={this.state.caseSearchString}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) =>
                                                this.setState({
                                                    caseSearchString: event.target.value,
                                                })
                                            }
                                        ></input>
                                    </div>
                                    <div
                                        className="float-end no-padding"
                                        style={{ display: 'inline-block' }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-gray btn-icon float-end"
                                            onClick={() => {
                                                this.setState({
                                                    caseSearchString: '',
                                                    caseSearchResults: [],
                                                });
                                            }}
                                        >
                                            <i className="fal fa-times color-white" />
                                        </button>
                                        <div
                                            style={{ display: 'inline-block' }}
                                            className="float-end"
                                        >
                                            <button
                                                type="submit"
                                                className="btn btn-black btn-icon float-end "
                                                onClick={() => this.runSearch(true)}
                                            >
                                                <i className="fal fa-search color-white" />
                                            </button>
                                        </div>
                                    </div>
                                    <span>
                                        <span className="text-danger">
                                            {this.state.validation.caseNameOrNumberSearch}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div style={{ maxHeight: '500px', overflowX: 'auto' }}>
                                {this.state.caseSearchResults ? (
                                    this.state.caseSearchResults.length > 0 ? (
                                        <div>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Case Name</th>
                                                        <th>Status</th>
                                                        <th>State</th>
                                                        <th />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.caseSearchResults.map(
                                                        (caseItem: ICaseModel, i: number) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{caseItem.caseName}</td>
                                                                    <td>
                                                                        {
                                                                            caseItem.caseStatus!
                                                                                .displayName!
                                                                        }
                                                                    </td>
                                                                    <td>{caseItem.state}</td>
                                                                    <td>
                                                                        <button
                                                                            className="btn btn-no-bg float-end text-gray"
                                                                            onClick={() => {
                                                                                this.props.handleCaseSelected(
                                                                                    caseItem
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className="fal fa-check-square" />
                                                                            &nbsp;Select
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <span>No results found!</span>
                                    )
                                ) : null}
                            </div>
                            <div className="dialog-btn-div">
                                {this.state.caseSearchResults.length > 0 &&
                                this.state.currentPageNumber + 1 <
                                    Math.ceil(this.state.totalCaseCount / PageSize) ? (
                                    <button
                                        className="btn btn-orange float-end text-gray horizontal-margin"
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    currentPageNumber:
                                                        this.state.currentPageNumber + 1,
                                                },
                                                this.runSearch
                                            )
                                        }
                                    >
                                        Next
                                    </button>
                                ) : null}
                                {this.state.caseSearchResults.length > 0 &&
                                this.state.currentPageNumber > 0 ? (
                                    <button
                                        className="btn btn-orange float-end text-gray horizontal-margin"
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    currentPageNumber:
                                                        this.state.currentPageNumber - 1,
                                                },
                                                this.runSearch
                                            )
                                        }
                                    >
                                        Previous
                                    </button>
                                ) : null}
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() => {
                                        this.setState({
                                            showCaseSearch: false,
                                            caseSearchResults: [],
                                            caseSearchString: '',
                                            currentPageNumber: 0,
                                        });
                                        this.props.onCancel();
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
