import * as React from 'react';

import {
    DocumentTypesEnum,
    RestrictedDocumentCategory_CaseExpertReport,
} from '../../utilities/Constants';
import { ICheckmateTaskModel, IDocumentModel } from '../../interfaces/ICase';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import { CheckmateDialog } from '../shared/dialog';
import Common from '../../stores/Common';
import { ILookupModel } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../shared/SortableHeader';
import UploadWorkProductModal from '../shared/UploadWorkProductModal';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

export interface IWorkProductProps {
    authorizedToEdit: boolean;
    cannotViewOrDownloadDocument?: boolean;
    categoryDocumentTypes?: ILookupModel[];
    displayUploadWorkProductDialog?: boolean;
    documents?: IDocumentModel[];
    entityGuid: string;
    entityTypeId: number;
    keepDocumentUploadDialogOpen?: boolean; // AR - 12/27/2023 - As per conv with Josh, these 2 are possible dups. Will revisit when this component is refactored.
    noteTypeId?: number;
    onCreateEntityBeforeUpload?: (keepDocumentUploadDialogOpen?: boolean) => void; // This is for a scenario where the parent entity for the document doesnt exist. Hence the parent will be responsible for creating the entity first and then the document.
    onDeleteComplete?: (documentGuid: string) => void;
    onSaveComplete?: (document: IDocumentModel) => void;
    parentEntityJson?: string;
    parentEntityRedirectUrl?: string;
    strategyTypeId?: number;
    tasks?: ICheckmateTaskModel[];
    title?: string;
    uploadOnly?: boolean;
    user: IUserModel;
}

interface IWorkProductState {
    currentWorkProduct: IDocumentModel;
    displayUploadWorkProductDialog?: boolean;
    documentGuidToDelete: string;
    documents?: IDocumentModel[];
    file?: File;
    pendingResponse: boolean;
    showConfirmRemoveDialog: boolean;
    taskGuid?: string;
    validation: IValidation;
}

export class WorkProduct extends React.Component<IWorkProductProps, IWorkProductState> {
    constructor(props: IWorkProductProps) {
        super(props);

        this.state = {
            pendingResponse: false,
            validation: {},
            showConfirmRemoveDialog: false,
            documentGuidToDelete: '',
            currentWorkProduct: { documentType: { id: DocumentTypesEnum.WorkProduct.Value } },
            displayUploadWorkProductDialog: props.displayUploadWorkProductDialog,
        };
    }

    componentDidMount() {
        this.setState({
            documents: this.props.documents,
        });

        if (this.props.keepDocumentUploadDialogOpen) {
            const newWorkProduct: IDocumentModel = {
                documentType: { id: DocumentTypesEnum.WorkProduct.Value },
            };
            this.setState({
                displayUploadWorkProductDialog: true,
                currentWorkProduct: newWorkProduct,
            });
        }
    }

    componentDidUpdate(prevProps: IWorkProductProps) {
        if (prevProps.documents !== this.props.documents) {
            this.setState({ documents: this.props.documents });
        }
        if (!prevProps.keepDocumentUploadDialogOpen && this.props.keepDocumentUploadDialogOpen) {
            const newWorkProduct: IDocumentModel = {
                documentType: { id: DocumentTypesEnum.WorkProduct.Value },
            };
            this.setState({
                displayUploadWorkProductDialog: true,
                currentWorkProduct: newWorkProduct,
            });
        }
    }

    downloadWorkProduct = async (
        event: React.FormEvent<HTMLButtonElement>,
        documentGuid: string,
        documentName?: string
    ) => {
        event.preventDefault();
        const xhr = new XMLHttpRequest();
        let url =
            '/' +
            ApiRoutes.EntityDocumentDownload.replace('{id}', this.props.entityGuid)
                .replace('{entitytypeid}', this.props.entityTypeId.toString())
                .replace('{documentid}', documentGuid);
        const queryStrings: string[] = [];
        if (this.props.noteTypeId)
            queryStrings.push('noteTypeId=' + this.props.noteTypeId.toString());
        if (this.props.strategyTypeId)
            queryStrings.push('strategyTypeId=' + this.props.strategyTypeId.toString());
        if (queryStrings.length > 0) url += '?' + queryStrings.join('&');

        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/msword');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                let fileName = documentName ? documentName : 'Work Product.doc';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i].trim();
                            if (currentItem.includes('filename=')) {
                                fileName = currentItem.replace('filename=', '').replace(/"/g, '');
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));

                this.setState({ pendingResponse: false });
            }
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                // TODO: display custom validation returned from the api call
                /*let validation = this.state.validation;
                validation.model = [DisplayMessages.DocumentDownloadError];
                this.setState({ pendingResponse: false, validation: validation });*/
            }
        };

        xhr.send();
    };

    showConfirmRemoveDailog = (documentGuid: string) => {
        this.setState({ showConfirmRemoveDialog: true, documentGuidToDelete: documentGuid });
    };

    clickCancel = () => {
        this.setState({
            showConfirmRemoveDialog: false,
        });
    };

    removeWorkProduct = async () => {
        this.setState({ pendingResponse: true });
        const response = await _apiClient.deleteEntityDocument(
            this.props.entityGuid,
            this.props.entityTypeId,
            this.state.documentGuidToDelete,
            this.props.noteTypeId,
            this.props.strategyTypeId
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ pendingResponse: false, showConfirmRemoveDialog: false });

        if (this.props.onDeleteComplete)
            this.props.onDeleteComplete(this.state.documentGuidToDelete);
        else {
            window.location.reload();
        }
    };

    getModifiedByName = (user?: IUserModel) => {
        if (user && user.profile && user.profile.firstName && user.profile.lastName) {
            return user.profile.firstName.concat(' ', user.profile.lastName);
        }
        return '';
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let documents = this.state.documents;
        if (documents) {
            if (key == 'modifiedDate') {
                documents = documents.sort(Sort.compareDate(key, subKey, order));
            } else {
                documents = documents.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }
        //currentCase.casePlan!.checkmateTasks = documents;
        this.setState({ documents: documents });
    };

    openNewWorkProductUploadDialog = () => {
        if (!this.props.entityGuid && this.props.onCreateEntityBeforeUpload) {
            this.props.onCreateEntityBeforeUpload(true);
            return;
        }

        const newWorkProduct: IDocumentModel = {
            documentType: { id: DocumentTypesEnum.WorkProduct.Value },
        };
        this.setState({
            displayUploadWorkProductDialog: true,
            currentWorkProduct: newWorkProduct,
        });
    };
    getUploadUrl = () => {
        let url =
            '/' +
            ApiRoutes.EntityDocumentUpload.replace('{id}', this.props.entityGuid).replace(
                '{entitytypeid}',
                this.props.entityTypeId.toString()
            );
        const queryStrings: string[] = [];
        if (this.props.noteTypeId) {
            queryStrings.push('noteTypeId=' + this.props.noteTypeId.toString());
        }
        if (this.props.strategyTypeId) {
            queryStrings.push('strategyTypeId=' + this.props.strategyTypeId.toString());
        }

        if (queryStrings.length > 0) {
            url += '?' + queryStrings.join('&');
        }

        return url;
    };

    openEditWorkProductUploadDialog = (document: IDocumentModel) => {
        this.setState({ displayUploadWorkProductDialog: true, currentWorkProduct: document });
    };

    closeUploadWorkProductDialog = () => {
        this.setState({
            displayUploadWorkProductDialog: false,
            currentWorkProduct: { documentType: { id: DocumentTypesEnum.WorkProduct.Value } },
        });
    };

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    setChangedValue(name: string, value: any) {
        const newWorkProduct = this.state.currentWorkProduct;
        if (!this.state.currentWorkProduct.documentType)
            this.state.currentWorkProduct.documentType = {
                id: DocumentTypesEnum.WorkProduct.Value,
            };

        const validation = this.state.validation;

        switch (name) {
            case 'title':
                newWorkProduct.title = value;
                validation.title = [''];
                break;
            case 'description':
                newWorkProduct.description = value;
                validation.description = [''];
                break;
            case 'task':
                //newWorkProduct.task = this.state.tasks.filter(x => x.guid == value)[0];
                validation.task = [''];
                break;

            default:
                break;
        }
        this.setState({
            currentWorkProduct: newWorkProduct,
        });
    }

    handleSaveComplete = (document: IDocumentModel) => {
        if (this.props.onSaveComplete) {
            this.props.onSaveComplete(document);
        }
    };

    isRestrictedDocument = (document: IDocumentModel) => {
        return (
            this.props.cannotViewOrDownloadDocument &&
            document.documentCategoryTypeIds?.includes(RestrictedDocumentCategory_CaseExpertReport)
        );
    };

    public render() {
        const showCategoryColumn = (this.props.categoryDocumentTypes?.length ?? 0) > 0;

        return (
            <div className="container-fluid admin-detail">
                {this.state.pendingResponse ? <Loader /> : null}
                <div>
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>

                <div>
                    {this.props.title && (
                        <div
                            className="text-gray margin-top-sm"
                            style={{ display: 'inline-block' }}
                        >
                            {this.props.title}
                        </div>
                    )}
                    {this.props.authorizedToEdit && (
                        <>
                            {!this.props.uploadOnly && (
                                <span className="btn btn-orange float-end">
                                    <Link
                                        to={{
                                            pathname: LocalRoutes.WorkProductDetail.replace(
                                                ':entityguid',
                                                this.props.entityGuid
                                            )
                                                .replace(
                                                    ':entitytypeid',
                                                    this.props.entityTypeId.toString()
                                                )
                                                .replace(':documentguid?', ''),
                                        }}
                                        state={{
                                            parentEntityJson: this.props.parentEntityJson || '',
                                            redirectUrl: this.props.parentEntityRedirectUrl,
                                        }}
                                        style={{ color: 'white' }}
                                    >
                                        Create
                                    </Link>
                                </span>
                            )}
                            <button
                                className="btn btn-orange float-end text-gray horizontal-margin"
                                onClick={this.openNewWorkProductUploadDialog}
                            >
                                Upload
                            </button>
                        </>
                    )}
                </div>
                {this.state.documents ? (
                    <table className="table">
                        <thead>
                            <tr style={{ verticalAlign: 'middle' }}>
                                <SortableHeader
                                    headerText="Title"
                                    sortKey="title"
                                    onSort={this.sortData}
                                />
                                <SortableHeader
                                    headerText="Filename"
                                    sortKey="fileName"
                                    onSort={this.sortData}
                                />
                                {showCategoryColumn && <th>Category</th>}
                                <SortableHeader
                                    headerText="Modified by"
                                    sortKey="modifiedBy"
                                    subKey="profile"
                                    subGrandKey="firstName"
                                    onSort={this.sortData}
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="Modified date"
                                    sortKey="modifiedDate"
                                    onSort={this.sortData}
                                    noWrap
                                />
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.documents.map((document: IDocumentModel, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{document.title ? document.title : ''}</td>
                                        <td>{document.fileName ? document.fileName : ''}</td>
                                        {showCategoryColumn && (
                                            <td>
                                                {document.documentCategoryTypeIds
                                                    ?.map(
                                                        (docCategoryTypeId) =>
                                                            this.props.categoryDocumentTypes?.find(
                                                                (docType) =>
                                                                    docType.id === docCategoryTypeId
                                                            )?.displayName
                                                    )
                                                    .join(' | ')}
                                            </td>
                                        )}
                                        <td>{this.getModifiedByName(document.modifiedBy)}</td>
                                        <td>{Common.dateFormat(document.modifiedDate)}</td>
                                        <td>
                                            {this.props.authorizedToEdit &&
                                            !this.isRestrictedDocument(document) ? (
                                                <button
                                                    className="btn btn-no-bg float-end"
                                                    onClick={() => {
                                                        this.showConfirmRemoveDailog(
                                                            document.guid!
                                                        );
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-trash-alt"></i>
                                                </button>
                                            ) : null}
                                            {this.props.authorizedToEdit &&
                                            !this.isRestrictedDocument(document) ? (
                                                /* document.uploaded ? ( */
                                                <button
                                                    className="btn btn-no-bg float-end"
                                                    onClick={() => {
                                                        this.openEditWorkProductUploadDialog(
                                                            document
                                                        );
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-edit"></i>
                                                </button>
                                            ) : /* ) : (
                                                    <span className="btn btn-no-bg float-end">
                                                        <Link
                                                            to={{
                                                                pathname:
                                                                    LocalRoutes.WorkProductDetail.replace(
                                                                        ':entityguid',
                                                                        this.props.entityGuid
                                                                    )
                                                                        .replace(
                                                                            ':entitytypeid',
                                                                            this.props.entityTypeId.toString()
                                                                        )
                                                                        .replace(
                                                                            ':documentguid?',
                                                                            document.guid!
                                                                        ),
                                                            }}
                                                            state={{
                                                                parentEntityJson:
                                                                    this.props.parentEntityJson ||
                                                                    '',
                                                                redirectUrl:
                                                                    this.props
                                                                        .parentEntityRedirectUrl,
                                                            }}
                                                        >
                                                            <i className="fal fa-lg fa-pen color-black" />
                                                        </Link>
                                                    </span>
                                                ) */
                                            null}
                                            {this.isRestrictedDocument(document) ? null : (
                                                <button
                                                    className="btn btn-no-bg float-end"
                                                    onClick={(
                                                        e: React.MouseEvent<HTMLButtonElement>
                                                    ) => {
                                                        this.downloadWorkProduct(
                                                            e,
                                                            document.guid!,
                                                            document.fileName
                                                        );
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-file-download" />
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <span>No Documents found!</span>
                )}

                {!!this.state.displayUploadWorkProductDialog && (
                    <UploadWorkProductModal
                        currentWorkProduct={this.state.currentWorkProduct}
                        entityTypeId={this.props.entityTypeId}
                        onHide={this.closeUploadWorkProductDialog}
                        onSaveComplete={this.handleSaveComplete}
                        open={!!this.state.displayUploadWorkProductDialog}
                        uploadUrl={this.getUploadUrl()}
                    />
                )}

                <CheckmateDialog
                    isShowingModal={this.state.showConfirmRemoveDialog}
                    body="Are you sure you want to delete the selected item?"
                    handleClose={this.clickCancel}
                    handleConfirm={this.removeWorkProduct}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
