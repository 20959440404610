import { Modal } from 'react-bootstrap';

export interface IConfirmationDialogProps {
    bodyComponent?: JSX.Element;
    cancelText: string;
    onCancel: () => void;
    onClose: () => void;
    onSubmit: () => void;
    open: boolean;
    showBackdrop?: boolean;
    submitText: string;
    title: string;
}

export function ConfirmationDialog(props: IConfirmationDialogProps) {
    return (
        <Modal centered show={props.open} onHide={props.onClose} backdrop={props.showBackdrop ?? false}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.bodyComponent}</Modal.Body>
            <Modal.Footer>
                <button className="btn btn-orange" onClick={props.onSubmit}>
                    {props.submitText ?? 'OK'}
                </button>
                <button className="btn btn-default" onClick={props.onCancel}>
                    {props.cancelText ?? 'Cancel'}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
