import * as React from 'react';

import {
    ApplicationHelpType,
    DataScopesEnum,
    EmptyGuid,
    EntityTypes,
    ExportOptions,
    LookupDataEnums,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    ReportTypesEnum,
    StrategyTypes,
    TaskActivityTypesEnum,
    TaskTypesEnum,
    UserRightsEnum,
} from '../../utilities/Constants';
import {
    ICaseStatisticsByDiagnosisModel,
    IDiscoveryServedModel,
    ILocalCounselStrategyDetailModel,
    IStrategyKeyRelationshipModel,
    IStrategyPrincipleModel,
    IStrategySummaryModel,
    IUpdateStrategyModel,
} from '../../interfaces/IStrategy';
import { INoteCategoryTypeModel, INoteModel } from '../../interfaces/INote';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../../components/shared/dialog';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { DocumentTitle } from '../../components/shared/DocumentTitle';
import EntityAssertionControl from '../../components/shared/EntityAssertionControl';
import { ExportFileButton } from '../../components/shared/ExportFileButton';
import { Help } from '../../components/shared/Help';
import { ICheckmateTaskModel } from '../../interfaces/ICase';
import { IEntityAssertionModel } from '../../interfaces/IAssertionDescriptor';
import { ILookupModel } from '../../interfaces/ILookup';
import { IProfileModel } from '../../interfaces/IProfile';
import { IReportCaseDetailModel } from '../../interfaces/Report/IReportCaseDetailModel';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import { NoteList } from '../../components/notes/NoteList';
import Sort from '../../stores/Sort';
import { TaskList } from '../../components/tasks/TaskList';
import ValidateUtils from '../../shared/validations';
import { WorkProductWrapper } from '../../components/work-product/WorkProductWrapper';

const _apiClient = new ApiClient();

interface ILocalCounselStrategyDetailProps {
    user: IUserModel;
    guid?: string;
}

interface ILocalCounselStrategyDetailState {
    pendingResponse: boolean;
    readOnly: boolean;
    validation: IValidation;
    strategyDetails?: ILocalCounselStrategyDetailModel;
    originalStrategyDetails?: ILocalCounselStrategyDetailModel;
    qScores: ILookupModel[];
    currentNarrativeCaseAssertionOnEditMode?: IEntityAssertionModel;
    ownerList: IUserModel[];
    noteCategoryTypes: INoteCategoryTypeModel[];
    currentNote: INoteModel;
    openNoteEditor: boolean;
    addEditPOC?: boolean;
    currentPOCAddEdit?: IProfileModel;
    currentPOCDeleteIndex?: number;
    showConfirmDeleteDialog?: boolean;
    loggedInUserDataScopeId: number;
    forceNoteEditorInReadOnlyMode?: boolean;
}

export class LocalCounselStrategyDetail extends React.Component<
    ILocalCounselStrategyDetailProps,
    ILocalCounselStrategyDetailState
> {
    constructor(props: ILocalCounselStrategyDetailProps) {
        super(props);

        this.state = {
            pendingResponse: true,
            readOnly: true,
            validation: {},
            qScores: [],
            ownerList: [],
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            noteCategoryTypes: [],
            openNoteEditor: false,
            loggedInUserDataScopeId: 0,
        };
    }

    componentDidMount() {
        if (
            !Authorization.isAuthorizedToStrategyType(
                StrategyTypes.LocalCounsel.Name,
                this.props.user
            )
        )
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadInitialLookupData();
        const id = this.props.guid;
        if (id) this.loadLocalCounselStrategyDetails(id);
    }

    loadLocalCounselStrategyDetails = async (id: string) => {
        const response = await _apiClient.getLocalCounselStrategyDetails(id);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            this.setState({
                strategyDetails: response.payload,
                originalStrategyDetails: JSON.parse(JSON.stringify(response.payload)),
                pendingResponse: false,
                readOnly: true,
                validation: {},
            });
        }
    };

    loadInitialLookupData = async () => {
        this.setState({ pendingResponse: true });
        const qScoreResponse = await _apiClient.getLookupData(LookupDataEnums.QScore);
        if (qScoreResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (qScoreResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    qScoreResponse.errors,
                    qScoreResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const usersResponse = await _apiClient.getCaseManagersInZone();
        if (usersResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (usersResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    usersResponse.errors,
                    usersResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const noteCategoryTypesResponse = await _apiClient.getNoteCategoryTypes(
            LookupDataEnums.StrategyNoteCategories
        );
        if (noteCategoryTypesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (noteCategoryTypesResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    noteCategoryTypesResponse.errors,
                    noteCategoryTypesResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );

        this.setState({
            qScores: qScoreResponse.payload!,
            ownerList: usersResponse.payload!,
            noteCategoryTypes: noteCategoryTypesResponse.payload!,
            pendingResponse: false,
            loggedInUserDataScopeId: loggedInUserDataScopeId,
        });
    };

    finalizeChange = (modifiedAssertions: IEntityAssertionModel[]) => {
        if (this.state.strategyDetails && modifiedAssertions.length == 1) {
            const strategyDetails = this.state.strategyDetails;
            const currentAssertion = modifiedAssertions[0];

            if (
                strategyDetails.strategy.guid === currentAssertion.guid &&
                strategyDetails.strategy.assertionDescriptor.guid ===
                    currentAssertion.assertionDescriptor.guid &&
                strategyDetails.strategy.entityType &&
                currentAssertion.entityType &&
                strategyDetails.strategy.entityType.id === currentAssertion.entityType.id &&
                strategyDetails.strategy.entityId === currentAssertion.entityId
            ) {
                strategyDetails.strategy.textValue = currentAssertion.textValue;
                strategyDetails.strategy.booleanValue = currentAssertion.booleanValue;
                strategyDetails.strategy.numericValue = currentAssertion.numericValue;
                strategyDetails.strategy.dateTimeValue = currentAssertion.dateTimeValue;
                strategyDetails.strategy.assertionDescriptorValue =
                    currentAssertion.assertionDescriptorValue;

                this.setState({
                    strategyDetails: strategyDetails,
                    currentNarrativeCaseAssertionOnEditMode: undefined,
                });
                return;
            }

            for (let i = 0; i < strategyDetails.principles.length; i++) {
                const principleItem = strategyDetails.principles[i];
                if (
                    principleItem.topic.guid === currentAssertion.guid &&
                    principleItem.topic.assertionDescriptor.guid ===
                        currentAssertion.assertionDescriptor.guid &&
                    principleItem.topic.entityType &&
                    currentAssertion.entityType &&
                    principleItem.topic.entityType.id === currentAssertion.entityType.id &&
                    principleItem.topic.entityId === currentAssertion.entityId &&
                    principleItem.topic.correlationKeyId === currentAssertion.correlationKeyId
                ) {
                    principleItem.topic.textValue = currentAssertion.textValue;
                    principleItem.topic.booleanValue = currentAssertion.booleanValue;
                    principleItem.topic.numericValue = currentAssertion.numericValue;
                    principleItem.topic.dateTimeValue = currentAssertion.dateTimeValue;
                    principleItem.topic.assertionDescriptorValue =
                        currentAssertion.assertionDescriptorValue;

                    this.setState({
                        strategyDetails: strategyDetails,
                        currentNarrativeCaseAssertionOnEditMode: undefined,
                    });
                    return;
                } else if (
                    principleItem.guidance.guid === currentAssertion.guid &&
                    principleItem.guidance.assertionDescriptor.guid ===
                        currentAssertion.assertionDescriptor.guid &&
                    principleItem.guidance.entityType &&
                    currentAssertion.entityType &&
                    principleItem.guidance.entityType.id === currentAssertion.entityType.id &&
                    principleItem.guidance.entityId === currentAssertion.entityId &&
                    principleItem.guidance.correlationKeyId === currentAssertion.correlationKeyId
                ) {
                    principleItem.guidance.textValue = currentAssertion.textValue;
                    principleItem.guidance.booleanValue = currentAssertion.booleanValue;
                    principleItem.guidance.numericValue = currentAssertion.numericValue;
                    principleItem.guidance.dateTimeValue = currentAssertion.dateTimeValue;
                    principleItem.guidance.assertionDescriptorValue =
                        currentAssertion.assertionDescriptorValue;

                    this.setState({
                        strategyDetails: strategyDetails,
                        currentNarrativeCaseAssertionOnEditMode: undefined,
                    });
                    return;
                }
            }

            for (let i = 0; i < strategyDetails.keyRelationships.length; i++) {
                const keyRelationshipItem = strategyDetails.keyRelationships[i];
                if (
                    keyRelationshipItem.name.guid === currentAssertion.guid &&
                    keyRelationshipItem.name.assertionDescriptor.guid ===
                        currentAssertion.assertionDescriptor.guid &&
                    keyRelationshipItem.name.entityType &&
                    currentAssertion.entityType &&
                    keyRelationshipItem.name.entityType.id === currentAssertion.entityType.id &&
                    keyRelationshipItem.name.entityId === currentAssertion.entityId &&
                    keyRelationshipItem.name.correlationKeyId === currentAssertion.correlationKeyId
                ) {
                    keyRelationshipItem.name.textValue = currentAssertion.textValue;
                    keyRelationshipItem.name.booleanValue = currentAssertion.booleanValue;
                    keyRelationshipItem.name.numericValue = currentAssertion.numericValue;
                    keyRelationshipItem.name.dateTimeValue = currentAssertion.dateTimeValue;
                    keyRelationshipItem.name.assertionDescriptorValue =
                        currentAssertion.assertionDescriptorValue;

                    this.setState({
                        strategyDetails: strategyDetails,
                        currentNarrativeCaseAssertionOnEditMode: undefined,
                    });
                    return;
                } else if (
                    keyRelationshipItem.position.guid === currentAssertion.guid &&
                    keyRelationshipItem.position.assertionDescriptor.guid ===
                        currentAssertion.assertionDescriptor.guid &&
                    keyRelationshipItem.position.entityType &&
                    currentAssertion.entityType &&
                    keyRelationshipItem.position.entityType.id === currentAssertion.entityType.id &&
                    keyRelationshipItem.position.entityId === currentAssertion.entityId &&
                    keyRelationshipItem.position.correlationKeyId ===
                        currentAssertion.correlationKeyId
                ) {
                    keyRelationshipItem.position.textValue = currentAssertion.textValue;
                    keyRelationshipItem.position.booleanValue = currentAssertion.booleanValue;
                    keyRelationshipItem.position.numericValue = currentAssertion.numericValue;
                    keyRelationshipItem.position.dateTimeValue = currentAssertion.dateTimeValue;
                    keyRelationshipItem.position.assertionDescriptorValue =
                        currentAssertion.assertionDescriptorValue;

                    this.setState({
                        strategyDetails: strategyDetails,
                        currentNarrativeCaseAssertionOnEditMode: undefined,
                    });
                    return;
                }
            }
        }
    };

    handleNarrativeFieldEdit = (item: IEntityAssertionModel) => {
        if (this.state.strategyDetails) {
            const strategyDetails = this.state.strategyDetails;

            // Strategy Assertion Descriptor
            if (
                strategyDetails.strategy.guid === item.guid &&
                strategyDetails.strategy.assertionDescriptor &&
                strategyDetails.strategy.assertionDescriptor.guid ===
                    item.assertionDescriptor.guid &&
                strategyDetails.strategy.entityType &&
                item.entityType &&
                strategyDetails.strategy.entityType.id === item.entityType.id &&
                strategyDetails.strategy.entityId === item.entityId
            ) {
                this.setState({
                    currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                        JSON.stringify(strategyDetails.strategy)
                    ),
                });
                return;
            }

            // One of the 2 Principles Assertion Descriptor
            for (let i = 0; i < strategyDetails.principles.length; i++) {
                const principleItem = strategyDetails.principles[i];
                if (
                    principleItem.topic.guid === item.guid &&
                    principleItem.topic.assertionDescriptor &&
                    item.assertionDescriptor &&
                    principleItem.topic.assertionDescriptor.guid ===
                        item.assertionDescriptor.guid &&
                    principleItem.topic.entityType &&
                    item.entityType &&
                    principleItem.topic.entityType.id === item.entityType.id &&
                    principleItem.topic.entityId === item.entityId &&
                    principleItem.topic.correlationKeyId === item.correlationKeyId
                ) {
                    this.setState({
                        currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                            JSON.stringify(principleItem.topic)
                        ),
                    });
                    return;
                } else if (
                    principleItem.guidance.guid === item.guid &&
                    principleItem.guidance.assertionDescriptor &&
                    item.assertionDescriptor &&
                    principleItem.guidance.assertionDescriptor.guid ===
                        item.assertionDescriptor.guid &&
                    principleItem.guidance.entityType &&
                    item.entityType &&
                    principleItem.guidance.entityType.id === item.entityType.id &&
                    principleItem.guidance.entityId === item.entityId &&
                    principleItem.guidance.correlationKeyId === item.correlationKeyId
                ) {
                    this.setState({
                        currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                            JSON.stringify(principleItem.guidance)
                        ),
                    });
                    return;
                }
            }

            // One of the 2 Key Relationships Assertion Descriptor
            for (let i = 0; i < strategyDetails.keyRelationships.length; i++) {
                const keyRelationshipsItem = strategyDetails.keyRelationships[i];
                if (
                    keyRelationshipsItem.name.guid === item.guid &&
                    keyRelationshipsItem.name.assertionDescriptor &&
                    item.assertionDescriptor &&
                    keyRelationshipsItem.name.assertionDescriptor.guid ===
                        item.assertionDescriptor.guid &&
                    keyRelationshipsItem.name.entityType &&
                    item.entityType &&
                    keyRelationshipsItem.name.entityType.id === item.entityType.id &&
                    keyRelationshipsItem.name.entityId === item.entityId &&
                    keyRelationshipsItem.name.correlationKeyId === item.correlationKeyId
                ) {
                    this.setState({
                        currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                            JSON.stringify(keyRelationshipsItem.name)
                        ),
                    });
                    return;
                } else if (
                    keyRelationshipsItem.position.guid === item.guid &&
                    keyRelationshipsItem.position.assertionDescriptor &&
                    item.assertionDescriptor &&
                    keyRelationshipsItem.position.assertionDescriptor.guid ===
                        item.assertionDescriptor.guid &&
                    keyRelationshipsItem.position.entityType &&
                    item.entityType &&
                    keyRelationshipsItem.position.entityType.id === item.entityType.id &&
                    keyRelationshipsItem.position.entityId === item.entityId &&
                    keyRelationshipsItem.position.correlationKeyId === item.correlationKeyId
                ) {
                    this.setState({
                        currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                            JSON.stringify(keyRelationshipsItem.position)
                        ),
                    });
                    return;
                }
            }
        }
    };

    handleClearNarrativeFieldEdit = () => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            const currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = '';
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    handleNarrativeFieldTextChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            const currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = event.target.value;
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    save = async () => {
        const validation: IValidation = {};
        if (!this.state.strategyDetails) {
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ validation: validation });
            return;
        }

        this.setState({ pendingResponse: true });

        const updateStrategyModel: IUpdateStrategyModel = {
            assertions: [],
            tasks: JSON.parse(JSON.stringify(this.state.strategyDetails.tasks)),
            strategyEntityType: StrategyTypes.LocalCounsel.Value,
            pOCs: JSON.parse(JSON.stringify(this.state.strategyDetails.poCs)),
        };
        updateStrategyModel.assertions.push(this.state.strategyDetails.strategy);
        for (let i = 0; i < this.state.strategyDetails.principles.length; i++) {
            updateStrategyModel.assertions.push(this.state.strategyDetails.principles[i].topic);
            updateStrategyModel.assertions.push(this.state.strategyDetails.principles[i].guidance);
        }

        for (let i = 0; i < this.state.strategyDetails.keyRelationships.length; i++) {
            updateStrategyModel.assertions.push(
                this.state.strategyDetails.keyRelationships[i].name
            );
            updateStrategyModel.assertions.push(
                this.state.strategyDetails.keyRelationships[i].position
            );
        }

        const response = await _apiClient.updateStrategyDetails(
            this.state.strategyDetails.guid,
            updateStrategyModel
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.loadLocalCounselStrategyDetails(this.state.strategyDetails.guid);
    };

    addNewPrincipleItem = () => {
        if (this.state.strategyDetails) {
            const strategyDetails = this.state.strategyDetails;
            const existingIds = strategyDetails.principles.map((item: IStrategyPrincipleModel) => {
                return item.correlationKeyId;
            });
            const maxCorrelationId = Math.max(...existingIds);
            strategyDetails.principles.push({
                correlationKeyId: maxCorrelationId + 1,
                topic: {
                    correlationKeyId: maxCorrelationId + 1,
                    assertionDescriptor: strategyDetails.principles[0].topic.assertionDescriptor,
                    entityType: strategyDetails.principles[0].topic.entityType,
                    entityGuid: strategyDetails.principles[0].topic.entityGuid,
                },
                guidance: {
                    correlationKeyId: maxCorrelationId + 1,
                    assertionDescriptor: strategyDetails.principles[0].guidance.assertionDescriptor,
                    entityType: strategyDetails.principles[0].guidance.entityType,
                    entityGuid: strategyDetails.principles[0].guidance.entityGuid,
                },
            });

            this.setState({ strategyDetails: strategyDetails });
        }
    };

    addNewKeyRelationshipItem = () => {
        if (this.state.strategyDetails) {
            const strategyDetails = this.state.strategyDetails;
            const existingIds = strategyDetails.keyRelationships.map(
                (item: IStrategyKeyRelationshipModel) => {
                    return item.correlationKeyId;
                }
            );
            const maxCorrelationId = Math.max(...existingIds);
            strategyDetails.keyRelationships.push({
                correlationKeyId: maxCorrelationId + 1,
                name: {
                    correlationKeyId: maxCorrelationId + 1,
                    assertionDescriptor:
                        strategyDetails.keyRelationships[0].name.assertionDescriptor,
                    entityType: strategyDetails.keyRelationships[0].name.entityType,
                    entityGuid: strategyDetails.keyRelationships[0].name.entityGuid,
                },
                position: {
                    correlationKeyId: maxCorrelationId + 1,
                    assertionDescriptor:
                        strategyDetails.keyRelationships[0].position.assertionDescriptor,
                    entityType: strategyDetails.keyRelationships[0].position.entityType,
                    entityGuid: strategyDetails.keyRelationships[0].position.entityGuid,
                },
            });

            this.setState({ strategyDetails: strategyDetails });
        }
    };

    refreshTasks = (tasks: ICheckmateTaskModel[]) => {
        if (this.state.strategyDetails) {
            const strategyDetails = this.state.strategyDetails;
            strategyDetails.tasks = JSON.parse(JSON.stringify(tasks));
            this.setState({ strategyDetails: strategyDetails });
        }
    };

    handleExportError = () => {
        const validation = this.state.validation;
        validation.model = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    handleCancelNoteEditor = () => {
        this.setState({
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            openNoteEditor: false,
        });
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        let matchFound = false;
        const strategyDetails = this.state.strategyDetails;
        for (let i = 0; i < strategyDetails!.notes.length; i++) {
            if (strategyDetails!.notes[i].guid == noteItem.guid) {
                matchFound = true;
                strategyDetails!.notes[i] = JSON.parse(JSON.stringify(noteItem));
                break;
            }
        }

        if (!matchFound) strategyDetails!.notes.push(JSON.parse(JSON.stringify(noteItem)));
        strategyDetails!.notes = strategyDetails!.notes.sort(
            Sort.compareDate('modifiedDate', undefined, 'desc')
        );
        this.setState({ strategyDetails: strategyDetails, openNoteEditor: false });
    };

    handleViewEditNote = (noteGuidToEdit: string, readOnly?: boolean) => {
        const validation: IValidation = {};

        const currentNote = this.state.strategyDetails!.notes.find((x) => x.guid == noteGuidToEdit);
        if (!currentNote) {
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ pendingResponse: false, validation: validation });
            return;
        }

        this.setState({
            currentNote: JSON.parse(JSON.stringify(currentNote)),
            openNoteEditor: true,
            forceNoteEditorInReadOnlyMode: readOnly,
        });
    };

    refreshNotes = (notes: INoteModel[]) => {
        const strategyDetails = this.state.strategyDetails;
        if (strategyDetails) {
            strategyDetails.notes = notes;
            this.setState({ strategyDetails: strategyDetails });
        }
    };

    handleAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.StrategyNote },
            status: { id: NoteStatusTypes.Open },
            parent: {
                entityType: { id: EntityTypes.LocalCounselStrategy },
                entityGuid: this.state.strategyDetails!.guid,
            },
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        this.setState({
            openNoteEditor: true,
            currentNote: currentNote,
            forceNoteEditorInReadOnlyMode: false,
        });
    };

    handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        let currentPOCToAddEdit = this.state.currentPOCAddEdit;
        const validation: IValidation = this.state.validation;
        if (!currentPOCToAddEdit) currentPOCToAddEdit = { guid: EmptyGuid };
        switch (event.target.name) {
            case 'designation':
                currentPOCToAddEdit.designation = event.target.value;
                break;
            case 'firstName':
                validation.firstName = [event.target.value ? '' : 'First Name is Required'];
                currentPOCToAddEdit.firstName = event.target.value;
                break;
            case 'lastName':
                validation.lastName = [event.target.value ? '' : 'Last Name is Required'];
                currentPOCToAddEdit.lastName = event.target.value;
                break;
            case 'email':
                currentPOCToAddEdit.email = event.target.value;
                validation.email = [Common.isValidEmail(event.target.value) ? '' : 'Invalid Email'];
                break;
            case 'comments':
                currentPOCToAddEdit.comments = event.target.value;
                break;
            case 'phone':
                if (event.target.value) {
                    if (!currentPOCToAddEdit.phones || currentPOCToAddEdit.phones.length === 0)
                        currentPOCToAddEdit.phones = [
                            {
                                phoneNumber: Common.formatPhoneNumber(event.target.value),
                                phoneType: { id: 1 },
                            },
                        ];
                    else
                        currentPOCToAddEdit.phones[0].phoneNumber = Common.formatPhoneNumber(
                            event.target.value
                        );
                } else currentPOCToAddEdit.phones = [];

                validation.phone = [
                    Common.isValidPhoneNumber(event.target.value)
                        ? ''
                        : 'Enter a valid phone number in the format XXX-XXX-XXXX',
                ];
                break;
            default:
        }

        this.setState({ currentPOCAddEdit: currentPOCToAddEdit, validation: validation });
    };

    deletePOC = () => {
        if (this.state.currentPOCDeleteIndex == undefined) {
            this.setState({ showConfirmDeleteDialog: false, currentPOCAddEdit: undefined });
            return;
        }

        const strategyDetails = this.state.strategyDetails;
        this.state.strategyDetails!.poCs.splice(this.state.currentPOCDeleteIndex, 1);
        this.setState({
            strategyDetails: strategyDetails,
            showConfirmDeleteDialog: false,
            currentPOCDeleteIndex: undefined,
        });
    };

    finalizaPOCChange = () => {
        if (this.state.currentPOCAddEdit) {
            const strategyDetails = this.state.strategyDetails;
            if (strategyDetails) {
                const currentPOCToAddEdit = this.state.currentPOCAddEdit;
                const validation: IValidation = this.state.validation;
                let hasErrors = false;
                if (!currentPOCToAddEdit.firstName) {
                    hasErrors = true;
                    validation.firstName = ['First Name is Required'];
                }

                if (!currentPOCToAddEdit.lastName) {
                    hasErrors = true;
                    validation.lastName = ['Last Name is Required'];
                }

                if (currentPOCToAddEdit.email && !Common.isValidEmail(currentPOCToAddEdit.email)) {
                    hasErrors = true;
                    validation.email = ['Invalid Email'];
                }

                if (
                    currentPOCToAddEdit.phones &&
                    currentPOCToAddEdit.phones.length > 0 &&
                    currentPOCToAddEdit.phones[0].phoneNumber &&
                    !Common.isValidPhoneNumber(currentPOCToAddEdit.phones[0].phoneNumber!)
                ) {
                    hasErrors = true;
                    validation.phone = ['Enter a valid phone number is the format XXX-XXX-XXXX'];
                }

                if (hasErrors) {
                    this.setState({ validation: validation });
                    return;
                }

                if (currentPOCToAddEdit.guid !== EmptyGuid) {
                    let match = this.state.strategyDetails!.poCs.find(
                        (x) => x.guid === currentPOCToAddEdit.guid
                    );
                    if (match) match = currentPOCToAddEdit;
                } else {
                    let match = this.state.strategyDetails!.poCs.find(
                        (x) => x.guid === EmptyGuid && x.index === currentPOCToAddEdit.index
                    );
                    if (match) match = currentPOCToAddEdit;
                    else strategyDetails.poCs.push(currentPOCToAddEdit);
                }

                this.setState({
                    strategyDetails: strategyDetails,
                    currentPOCAddEdit: undefined,
                    addEditPOC: false,
                });
            }
        }
    };

    render() {
        if (this.state.pendingResponse || !this.state.strategyDetails) return <Loader />;

        if (this.state.openNoteEditor)
            return (
                <>
                    <DocumentTitle title={this.state.strategyDetails!.name} />
                    <NoteEditor
                        forceNoteEditorInReadOnlyMode={this.state.forceNoteEditorInReadOnlyMode}
                        authorizedToEdit={NoteHelper.isUserAuthorizedToEditNote(
                            this.state.currentNote,
                            this.props.user,
                            Authorization.userHasRight(
                                UserRightsEnum.ViewLocalCounselStrategyNote,
                                this.props.user
                            ),
                            Authorization.userHasRight(
                                UserRightsEnum.UpdateLocalCounselStrategyNote,
                                this.props.user
                            ),
                            Authorization.userHasRight(
                                UserRightsEnum.AddLocalCounselStrategyNote,
                                this.props.user
                            )
                        )}
                        strategyTypeId={StrategyTypes.LocalCounsel.Value}
                        user={this.props.user}
                        originEntityName={this.state.strategyDetails.name}
                        originEntityKey={this.state.strategyDetails.guid}
                        originEntityType={StrategyTypes.LocalCounsel.Value}
                        noteCategoryTypes={this.state.noteCategoryTypes}
                        caseLocalCounselGuid={this.state.currentNote.localCounselOrganizationGuid}
                        defaultAlertTarget={
                            Authorization.isLocalScope(this.props.user)
                                ? this.state.currentNote.caseManager
                                : undefined
                        }
                        defaultWatchTargets={
                            Authorization.isLocalScope(this.props.user) &&
                            this.state.currentNote.alternateCaseManager
                                ? [this.state.currentNote.alternateCaseManager]
                                : undefined
                        }
                        currentNote={this.state.currentNote}
                        handleSaveComplete={this.onSaveNoteComplete}
                        handleCancel={this.handleCancelNoteEditor}
                    />
                </>
            );

        return (
            <div>
                <DocumentTitle title={this.state.strategyDetails!.name} />
                <h1>
                    {this.state.strategyDetails!.name}

                    <span>
                        {this.state.readOnly ? (
                            <span>
                                <ExportFileButton
                                    url={'/' + ApiRoutes.ExportReport}
                                    reportType={{
                                        id: ReportTypesEnum.LocalCounselStrategyDetailExport.Value,
                                        availableReportOptions: {
                                            customOptions: [
                                                ExportOptions.IncludeTactics,
                                                ExportOptions.IncludeAttachments,
                                                ExportOptions.IncludeStrategyNotes,
                                            ],
                                        },
                                    }}
                                    reportParameters={{
                                        reportType: {
                                            id: ReportTypesEnum.LocalCounselStrategyDetailExport
                                                .Value,
                                        },
                                        options: { key: this.state.strategyDetails.guid },
                                    }}
                                    onError={this.handleExportError}
                                    icon="fa-file-pdf"
                                />
                                {Authorization.userHasRight(
                                    UserRightsEnum.AddEditDeleteLocalCounselStrategy,
                                    this.props.user
                                ) ? (
                                    <button
                                        className="btn btn-no-bg float-end text-gray"
                                        onClick={() => this.setState({ readOnly: false })}
                                    >
                                        <i className="fal fa-pen" />
                                        &nbsp;Edit
                                    </button>
                                ) : null}
                                <a className="btn btn-no-bg float-end text-gray" href="#Notes">
                                    <i className="fal fa-memo" />
                                    &nbsp;Strategy Notes
                                </a>
                                <a
                                    className="btn btn-no-bg float-end text-gray"
                                    href={window.location.pathname + '#AttachmentsDiv'}
                                >
                                    <i className="fal fa-paperclip" />
                                    &nbsp;Attachments
                                </a>
                                <Link
                                    target="_blank"
                                    to={LocalRoutes.MeetingAgenda.replace(
                                        ':guid',
                                        this.state.strategyDetails!.entityGuid
                                    )}
                                >
                                    <span className="btn btn-no-bg float-end text-gray">
                                        <i className="fal fa-calendar-edit" />
                                        &nbsp; Meeting Agenda
                                    </span>
                                </Link>
                            </span>
                        ) : (
                            <span>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({
                                            strategyDetails: JSON.parse(
                                                JSON.stringify(this.state.originalStrategyDetails)
                                            ),
                                            readOnly: true,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <input
                                    type="button"
                                    className="btn btn-orange float-end"
                                    onClick={this.save}
                                    value="Save"
                                />
                            </span>
                        )}
                    </span>
                </h1>

                <span className="text-danger">{this.state.validation.model}</span>

                <table className="table thick-outer-border">
                    <tbody>
                        <tr>
                            <td className="col-sm-4 border-0 bold">Disease</td>
                            {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                (item: ICaseStatisticsByDiagnosisModel, index: number) => {
                                    return (
                                        <td className=" border-0 bold text-center" key={index}>
                                            {item.diagnosis}
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                        <tr>
                            <td className="col-sm-4">Open-Active Cases</td>
                            {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                (item: ICaseStatisticsByDiagnosisModel, index: number) => {
                                    return (
                                        <td className=" text-center" key={index}>
                                            {item.openActiveCaseCount}
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                        <tr>
                            <td className="col-sm-4">Open-Inactive Cases</td>
                            {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                (item: ICaseStatisticsByDiagnosisModel, index: number) => {
                                    return (
                                        <td className=" text-center" key={index}>
                                            {item.openInactiveCaseCount}
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                        <tr>
                            <td className="col-sm-4">Alt-Monitored Cases</td>
                            {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                (item: ICaseStatisticsByDiagnosisModel, index: number) => {
                                    return (
                                        <td className=" text-center" key={index}>
                                            {item.altMonitoredCaseCount}
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                        <tr>
                            <td className="col-sm-4">
                                {'Cases with Trial Date < 12 months'}
                                <Help
                                    type={ApplicationHelpType.Info}
                                    title="Cases with Trial Date < 12 months"
                                    helpText="Open - Active, Open - Inactive and Alt - Monitored Cases with Cases with Trial Date < 12 months"
                                />
                            </td>
                            {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                (item: ICaseStatisticsByDiagnosisModel, index: number) => {
                                    return (
                                        <td className=" text-center" key={index}>
                                            {item.trialDateOver12MonthsCaseCount}
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                        <tr>
                            <td className="col-sm-4">
                                Aged Inventory
                                <Help
                                    type={ApplicationHelpType.Info}
                                    title="Aged Inventory"
                                    helpText="Open - Active Cases with Date Served > 3 years"
                                />
                            </td>
                            {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                (item: ICaseStatisticsByDiagnosisModel, index: number) => {
                                    return (
                                        <td className=" text-center" key={index}>
                                            {item.agedInventoryCount}
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                        <tr>
                            <td className="col-sm-4">3-year Average $/case settled</td>
                            {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                (item: ICaseStatisticsByDiagnosisModel, index: number) => {
                                    return (
                                        <td className="text-center" key={index}>
                                            {Common.formatCurrency(
                                                item.threeYearAverageSettlementAmount.toString()
                                            )}
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                        <tr>
                            <td className="col-sm-4">Historic Average $/case settled</td>
                            {this.state.strategyDetails.caseStatisticsByDiagnosis.map(
                                (item: ICaseStatisticsByDiagnosisModel, index: number) => {
                                    return (
                                        <td className="text-center" key={index}>
                                            {Common.formatCurrency(
                                                item.historicAverageSettlementAmount.toString()
                                            )}
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                        {this.state.strategyDetails.jurisdictionsWithOpenActiveCases.length > 0 && (
                            <tr>
                                <td className="col-sm-4">Jurisdictions with Open Active Cases</td>
                                <td colSpan={5}>
                                    <ul>
                                        {this.state.strategyDetails.jurisdictionsWithOpenActiveCases.map(
                                            (item: IStrategySummaryModel, index: number) => {
                                                return (
                                                    <li key={index}>
                                                        {item.guid != EmptyGuid ? (
                                                            <Link
                                                                to={LocalRoutes.JurisdictionStrategyDetail.replace(
                                                                    ':guid',
                                                                    item.guid!
                                                                )}
                                                                target="_blank"
                                                            >
                                                                {item.name}{' '}
                                                            </Link>
                                                        ) : (
                                                            <span>{item.name}</span>
                                                        )}{' '}
                                                        | {item.openActiveCaseCount}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </td>
                            </tr>
                        )}
                        {this.state.strategyDetails.plaintiffFirmsWithOpenActiveCases.length >
                            0 && (
                            <tr>
                                <td className="col-sm-4">
                                    Plaintiffs Firms with Open Active Cases
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        helpText="The number of cases per firm represents cases in which the designated firm is local or national plaintiffs firm; the same case could be counted twice in these stats if the case is associated with both a local and national firm"
                                        title="Plaintiffs Firms with Open Active Cases"
                                    />
                                </td>
                                <td colSpan={5}>
                                    <ul>
                                        {this.state.strategyDetails.plaintiffFirmsWithOpenActiveCases.map(
                                            (item: IStrategySummaryModel, index: number) => {
                                                return (
                                                    <li key={index}>
                                                        {item.guid != EmptyGuid ? (
                                                            <Link
                                                                to={LocalRoutes.PlaintiffFirmStrategyDetail.replace(
                                                                    ':guid',
                                                                    item.guid!
                                                                )}
                                                                target="_blank"
                                                            >
                                                                {item.name}{' '}
                                                            </Link>
                                                        ) : (
                                                            <span>{item.name}</span>
                                                        )}{' '}
                                                        | {item.openActiveCaseCount}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </td>
                            </tr>
                        )}
                        {this.state.strategyDetails.outstandingAuthorityCases.length > 0 && (
                            <tr>
                                <td className="col-sm-4">Cases with Outstanding Authority</td>
                                <td colSpan={5}>
                                    <ul>
                                        {this.state.strategyDetails.outstandingAuthorityCases.map(
                                            (item: IReportCaseDetailModel, index: number) => {
                                                return (
                                                    <li key={index}>
                                                        <Link
                                                            to={LocalRoutes.CaseFile.replace(
                                                                ':guid',
                                                                item.caseGuid!
                                                            )}
                                                            target="_blank"
                                                        >
                                                            {item.caseName}{' '}
                                                        </Link>{' '}
                                                        |{' '}
                                                        {Common.formatCurrency(
                                                            item.authorityGranted!.toString()
                                                        )}{' '}
                                                        |{' '}
                                                        {Common.dateFormat(
                                                            item.authorityGrantedDate!.toString()
                                                        )}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </td>
                            </tr>
                        )}
                        {this.state.strategyDetails.discoveryServedCases.length > 0 && (
                            <tr>
                                <td className="col-sm-4">Cases with Discovery Served</td>
                                <td colSpan={5}>
                                    <ul>
                                        {this.state.strategyDetails.discoveryServedCases.map(
                                            (item: IDiscoveryServedModel, index: number) => {
                                                return (
                                                    <li key={index}>
                                                        <Link
                                                            to={LocalRoutes.CaseFile.replace(
                                                                ':guid',
                                                                item.caseGuid!
                                                            )}
                                                            target="_blank"
                                                        >
                                                            {item.caseName}{' '}
                                                        </Link>{' '}
                                                        |{' '}
                                                        {item.type.displayName +
                                                            (item.dueDate
                                                                ? ' | ' +
                                                                  Common.dateFormat(
                                                                      item.dueDate!.toString()
                                                                  )
                                                                : '')}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td className="col-sm-4">
                                Performance
                                <Help
                                    type={ApplicationHelpType.Info}
                                    title="Performance"
                                    helpText="Average Case Life Span:  Average of (Date of Resolution - Date Served) for all Resolved Cases\n\nDismissal Rate:  (# of Dismissed Cases) / (# of Resolved Cases)\n\nSettlement Rate:  (# of Settled Cases) / (# of resolved cases)\n\n% of Settlements under Authority:  (# of Cases Settled under Authority) / (Total # of Cases settled)\n\nAverage Time between Authority Approved and Resolution Date:  Average of (Date of Resolution - Authority Granted Date) for all Resolved Cases that had Authority Granted"
                                />
                            </td>
                            <td colSpan={5}>
                                <ul>
                                    <li>
                                        Average Case Life Span |{' '}
                                        {this.state.strategyDetails.performance.averageCaseLifeSpan}{' '}
                                        day(s)
                                    </li>
                                    <li>
                                        Dismissal Rate |{' '}
                                        {this.state.strategyDetails.performance.dismissalRate}%
                                    </li>
                                    <li>
                                        Settlement Rate |{' '}
                                        {this.state.strategyDetails.performance.settlementRate}%
                                    </li>
                                    <li>
                                        % of Settlements under Authority |{' '}
                                        {
                                            this.state.strategyDetails.performance
                                                .settlementUnderAuthorityCasePercentage
                                        }
                                    </li>
                                    <li>
                                        Average Time between Authority Approved and Resolution Date
                                        |{' '}
                                        {
                                            this.state.strategyDetails.performance
                                                .averageTimeBetweenAuthApprovalAndResolution
                                        }{' '}
                                        day(s)
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="row mb-2">
                    <div className="col-sm-2">
                        <label className="text-gray">Points of Contact</label>&nbsp;
                        {this.state.readOnly ? null : (
                            <button
                                className="btn btn-default btn-icon"
                                onClick={() =>
                                    this.setState({
                                        addEditPOC: true,
                                        currentPOCAddEdit: {
                                            guid: EmptyGuid,
                                            index:
                                                this.state.strategyDetails!.poCs &&
                                                this.state.strategyDetails!.poCs.length > 0
                                                    ? Math.max(
                                                          ...this.state.strategyDetails!.poCs.map(
                                                              (x) => x.index || 0
                                                          )
                                                      ) + 1
                                                    : 1,
                                        },
                                    })
                                }
                            >
                                <i className="fal fa-lg fa-plus" />
                            </button>
                        )}
                    </div>

                    <div className="col-sm-10">
                        {(this.state.strategyDetails!.poCs?.length ?? 0) > 0 && (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="col-sm-2">Name</th>
                                        <th className="col-sm-2">Title/Role</th>
                                        <th className="col-sm-2">Phone</th>
                                        <th className="col-sm-2">Email</th>
                                        <th
                                            className={
                                                this.state.readOnly ? 'col-sm-4' : 'col-sm-2'
                                            }
                                        >
                                            Comment
                                        </th>
                                        {this.state.readOnly ? null : <th />}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.strategyDetails.poCs.map(
                                        (item: IProfileModel, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="col-sm-2">
                                                        {(item.firstName || '') +
                                                            ' ' +
                                                            item.lastName}
                                                    </td>
                                                    <td className="col-sm-2">{item.designation}</td>
                                                    <td className="col-sm-2">
                                                        {item.phones && item.phones.length > 0
                                                            ? item.phones[0].phoneNumber
                                                            : ''}
                                                    </td>
                                                    <td className="col-sm-2">{item.email}</td>
                                                    <td
                                                        className={
                                                            this.state.readOnly
                                                                ? 'col-sm-4'
                                                                : 'col-sm-2'
                                                        }
                                                    >
                                                        {item.comments}
                                                    </td>
                                                    {this.state.readOnly ? null : (
                                                        <td className="col-sm-2">
                                                            <button
                                                                className="btn btn-no-bg float-end"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showConfirmDeleteDialog:
                                                                            true,
                                                                        currentPOCDeleteIndex:
                                                                            index,
                                                                    });
                                                                }}
                                                            >
                                                                <i className="fal fa-lg fa-trash-alt"></i>
                                                            </button>
                                                            <button
                                                                className="btn btn-no-bg float-end"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        addEditPOC: true,
                                                                        currentPOCAddEdit: item,
                                                                    })
                                                                }
                                                            >
                                                                <i className="fal fa-lg fa-pen" />
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-2">
                        <label className="text-gray">Key Relationships</label>&nbsp;
                        {this.state.readOnly ? null : (
                            <button
                                className="btn btn-default btn-icon"
                                onClick={this.addNewKeyRelationshipItem}
                            >
                                <i className="fal fa-lg fa-plus" />
                            </button>
                        )}
                    </div>
                    <div className="col-sm-10">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="col-sm-3">Name</th>
                                    <th className="col-sm-9">Position</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.strategyDetails.keyRelationships.map(
                                    (item: IStrategyKeyRelationshipModel, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td className="col-sm-3 align-top">
                                                    <EntityAssertionControl
                                                        assertionDescriptor={
                                                            item.name.assertionDescriptor
                                                        }
                                                        entityAssertions={[item.name]}
                                                        editMode={!this.state.readOnly}
                                                        refreshChange={this.finalizeChange}
                                                        handleNarrativeFieldEdit={
                                                            this.handleNarrativeFieldEdit
                                                        }
                                                        narrativeFieldAsPopup={true}
                                                    />
                                                </td>
                                                <td className="col-sm-9 align-top">
                                                    <EntityAssertionControl
                                                        assertionDescriptor={
                                                            item.position.assertionDescriptor
                                                        }
                                                        entityAssertions={[item.position]}
                                                        editMode={!this.state.readOnly}
                                                        refreshChange={this.finalizeChange}
                                                        handleNarrativeFieldEdit={
                                                            this.handleNarrativeFieldEdit
                                                        }
                                                        narrativeFieldAsPopup={true}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-2">
                        <label className="text-gray">Strategy</label>
                    </div>
                    <div className="col-sm-10">
                        <EntityAssertionControl
                            assertionDescriptor={
                                this.state.strategyDetails.strategy.assertionDescriptor
                            }
                            entityAssertions={[this.state.strategyDetails.strategy]}
                            editMode={!this.state.readOnly}
                            refreshChange={this.finalizeChange}
                            handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                            narrativeFieldAsPopup={true}
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-2">
                        <label className="text-gray">Principles</label>&nbsp;
                        {this.state.readOnly ? null : (
                            <button
                                className="btn btn-default btn-icon"
                                onClick={this.addNewPrincipleItem}
                            >
                                <i className="fal fa-lg fa-plus" />
                            </button>
                        )}
                    </div>
                    <div className="col-sm-10">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="col-sm-3">Topic</th>
                                    <th className="col-sm-9">Guidance/Position</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.strategyDetails.principles.map(
                                    (item: IStrategyPrincipleModel, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td className="col-sm-3 align-top">
                                                    <EntityAssertionControl
                                                        assertionDescriptor={
                                                            item.topic.assertionDescriptor
                                                        }
                                                        entityAssertions={[item.topic]}
                                                        editMode={!this.state.readOnly}
                                                        refreshChange={this.finalizeChange}
                                                        handleNarrativeFieldEdit={
                                                            this.handleNarrativeFieldEdit
                                                        }
                                                        narrativeFieldAsPopup={true}
                                                    />
                                                </td>
                                                <td className="col-sm-9 align-top">
                                                    <EntityAssertionControl
                                                        assertionDescriptor={
                                                            item.guidance.assertionDescriptor
                                                        }
                                                        entityAssertions={[item.guidance]}
                                                        editMode={!this.state.readOnly}
                                                        refreshChange={this.finalizeChange}
                                                        handleNarrativeFieldEdit={
                                                            this.handleNarrativeFieldEdit
                                                        }
                                                        narrativeFieldAsPopup={true}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <TaskList
                    user={this.props.user}
                    taskType={TaskTypesEnum.StrategyTactics}
                    parentEntityGuid=""
                    parentEntityType={{ id: EntityTypes.LocalCounselStrategy }}
                    defaultTaskActivityTypeId={TaskActivityTypesEnum.AnalysisStrategy}
                    ownerList={this.state.ownerList}
                    readOnly={this.state.readOnly}
                    refreshParent={this.refreshTasks}
                    title="Tactics"
                    tasks={this.state.strategyDetails.tasks}
                />

                {!!this.state.currentNarrativeCaseAssertionOnEditMode && (
                    <Modal
                        centered
                        show={true}
                        onHide={() =>
                            this.setState({ currentNarrativeCaseAssertionOnEditMode: undefined })
                        }
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {
                                    this.state.currentNarrativeCaseAssertionOnEditMode
                                        .assertionDescriptor!.displayName
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div>
                                <textarea
                                    className="form-control"
                                    value={
                                        this.state.currentNarrativeCaseAssertionOnEditMode.textValue
                                    }
                                    name={
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            .assertionDescriptor!.guid
                                    }
                                    rows={10}
                                    onChange={this.handleNarrativeFieldTextChange}
                                />
                            </div>
                            <div className="mt-2">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() =>
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            ? this.finalizeChange([
                                                  this.state
                                                      .currentNarrativeCaseAssertionOnEditMode,
                                              ])
                                            : {}
                                    }
                                >
                                    OK
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({
                                            currentNarrativeCaseAssertionOnEditMode: undefined,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleClearNarrativeFieldEdit}
                                >
                                    Clear
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}

                {this.state.readOnly && (
                    <div className="row" id="AttachmentsDiv">
                        <WorkProductWrapper
                            uploadOnly={true}
                            user={this.props.user}
                            entityGuid={this.state.strategyDetails.guid!}
                            entityTypeId={EntityTypes.LocalCounselStrategy}
                            strategyTypeId={StrategyTypes.LocalCounsel.Value}
                            title="Attachments"
                            documents={this.state.strategyDetails.documents}
                            parentEntityJson={JSON.stringify(this.state.strategyDetails)}
                            parentEntityRedirectUrl={LocalRoutes.LocalCounselStrategyDetail.replace(
                                ':guid',
                                this.state.strategyDetails.guid!
                            )}
                            authorizedToEdit={Authorization.userHasRight(
                                UserRightsEnum.AddEditDeleteLocalCounselStrategy,
                                this.props.user
                            )}
                            onSaveComplete={() => {
                                if (this.props.guid) {
                                    this.loadLocalCounselStrategyDetails(this.props.guid);
                                }
                            }}
                        />
                    </div>
                )}

                {this.state.readOnly ? (
                    <div className="row" id="Notes" style={{ marginTop: '15px' }}>
                        {/*No explicit View Strategy Note Right. User has access to Strategy Notes if they have access to the corresponding Strategy Page*/}
                        <NoteList
                            titleClassName="text-gray font-size-sm"
                            user={this.props.user}
                            notes={this.state.strategyDetails.notes}
                            noteCategoryTypes={this.state.noteCategoryTypes}
                            handleAddNote={this.handleAddNote}
                            handleViewEditNote={this.handleViewEditNote}
                            refreshParent={this.refreshNotes}
                            allowView={Authorization.userHasRight(
                                UserRightsEnum.ViewLocalCounselStrategyNote,
                                this.props.user
                            )}
                            title="Strategy Notes"
                            allowEdit={
                                Authorization.userHasRight(
                                    UserRightsEnum.UpdateLocalCounselStrategyNote,
                                    this.props.user
                                ) &&
                                (this.state.loggedInUserDataScopeId !==
                                    DataScopesEnum.LocalBasic.Value ||
                                    this.state.currentNote.allowLocalEdit == true)
                            }
                            allowDelete={Authorization.userHasRight(
                                UserRightsEnum.DeleteLocalCounselStrategyNote,
                                this.props.user
                            )}
                            allowAddNew={Authorization.userHasRight(
                                UserRightsEnum.AddLocalCounselStrategyNote,
                                this.props.user
                            )}
                        />
                    </div>
                ) : null}

                {this.state.addEditPOC && this.state.currentPOCAddEdit ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() => this.setState({ currentPOCAddEdit: undefined })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Add/Edit Point of Contact</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">
                                    {this.state.validation.addEditPOC}
                                </span>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    <label htmlFor="firstName">First Name*</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="firstName"
                                        value={this.state.currentPOCAddEdit.firstName}
                                        onChange={this.handleChange}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.firstName}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    <label htmlFor="lastName">Last Name*</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="lastName"
                                        value={this.state.currentPOCAddEdit.lastName}
                                        onChange={this.handleChange}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.lastName}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    <label htmlFor="designation">Title/ Role</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="designation"
                                        value={this.state.currentPOCAddEdit.designation}
                                        onChange={this.handleChange}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.designation}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    {' '}
                                    <label htmlFor="phone">Phone</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="tel"
                                        name="phone"
                                        placeholder="XXX-XXX-XXXX"
                                        value={
                                            this.state.currentPOCAddEdit.phones &&
                                            this.state.currentPOCAddEdit.phones.length > 0
                                                ? this.state.currentPOCAddEdit.phones[0].phoneNumber
                                                : ''
                                        }
                                        onChange={this.handleChange}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.phone}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    {' '}
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        value={this.state.currentPOCAddEdit.email}
                                        onChange={this.handleChange}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.email}
                                    </span>
                                </div>
                            </div>
                            <div className="row py-1">
                                <div className="col-sm-4">
                                    {' '}
                                    <label htmlFor="comments">Comments</label>
                                </div>
                                <div className="col-sm-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="comments"
                                        maxLength={100}
                                        value={this.state.currentPOCAddEdit.comments}
                                        onChange={this.handleChange}
                                    />
                                    <span className="text-danger">
                                        {this.state.validation.comments}
                                    </span>
                                </div>
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={this.finalizaPOCChange}
                                >
                                    OK
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() => this.setState({ currentPOCAddEdit: undefined })}
                                >
                                    Cancel
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                <CheckmateDialog
                    isShowingModal={this.state.showConfirmDeleteDialog || false}
                    body="Are you sure you want to delete this item? This operation is permanent and cannot be reverted back."
                    handleClose={() => this.setState({ showConfirmDeleteDialog: false })}
                    handleConfirm={this.deletePOC}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
