import React from 'react';

interface IWholeNumberInputProps {
    className?: string;
    disabled?: boolean;
    placeholder?: string;
    name?: string;
    style?: object;
    value: string | number | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export function WholeNumberInput(props: IWholeNumberInputProps) {
    const [val, setVal] = React.useState<string | number | undefined>(props.value);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[\d]*$/;
        const inputValue = e.target.value;
        if (inputValue === '' || regex.test(inputValue)) {
            setVal(inputValue);
            props.onChange(e);
        }
    };

    const allStyles = { ...{ width: '25%', maxWidth: '160px' }, ...props.style };

    const classes = 'form-control d-inline-block ms-2 ' + props.className;

    return (
        <input
            style={allStyles}
            className={classes}
            name={props.name}
            onChange={handleChange}
            value={val}
            placeholder={props.placeholder}
            disabled={props.disabled}
        />
    );
}
