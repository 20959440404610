import * as React from 'react';

import { IReportParametersModel, IReportTypeModel } from '../../interfaces/Report/IReport';
import { Modal, Stack } from 'react-bootstrap';

import { IValidation } from '../../interfaces/IError';

export interface IExportModalProps {
    cancelbuttonText?: string;
    modalTitle?: string;
    onClose?: () => void;
    onError?: () => void;
    onExportCompleted?: () => void;
    open: boolean;
    reportParameters?: IReportParametersModel;
    reportType: IReportTypeModel;
    submitButtonText?: string;
    url: string;
}

interface IExportModalState {
    showSettingsModal?: boolean;
    pendingResponse: boolean;
    validation: IValidation;
    saveReport?: boolean;
}

interface IStringBooleanMap {
    [key: string]: boolean;
}

export function ExportModal(props: IExportModalProps) {
    const [state, setState] = React.useState<IExportModalState>({
        pendingResponse: false,
        validation: {},
    });
    const [validation, setValidation] = React.useState<IValidation>({});
    const [selectedCustomOptions, setSelectedCustomOptions] = React.useState<IStringBooleanMap>({});

    const generateAndDownloadReport = async () => {
        setState((prev) => ({ ...prev, pendingResponse: true }));
        setValidation({});

        const xhr = new XMLHttpRequest();
        xhr.open('POST', props.url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                let fileName = '';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
                setState((prev) => ({
                    ...prev,
                    saveReport: false,
                    pendingResponse: false,
                    showSettingsModal: false,
                }));

                if (typeof props.onExportCompleted === 'function') {
                    props.onExportCompleted();
                }
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                setState((prev) => ({
                    ...prev,
                    pendingResponse: false,
                }));
                setValidation({ export: ['Failed to export data'] });

                if (typeof props.onError === 'function') {
                    props.onError();
                }
            }
        };

        let reportParamsString = '';

        const reportParams: IReportParametersModel = props.reportParameters || {
            reportType: { id: props.reportType.id },
        };

        reportParams.saveReport = state.saveReport;

        if (reportParams.options) {
            reportParams.options = { ...reportParams.options, ...selectedCustomOptions };
        } else if (!reportParams.options) {
            reportParams.options = { ...selectedCustomOptions };
        }

        reportParamsString = JSON.stringify(reportParams);

        xhr.send(reportParamsString);
    };

    const handleCustomOptionChange = (key: string, checked: boolean) => {
        setSelectedCustomOptions((prev) => ({ ...prev, [key]: checked }));
    };

    return (
        <Modal
            centered
            show={props.open}
            onHide={() => {
                setState((prev) => ({ ...prev, showSettingsModal: false }));
            }}
            backdrop={false}
        >
            <Modal.Header>
                <Modal.Title>{props.modalTitle ?? 'Export'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    {Object.values(
                        props.reportType.availableReportOptions?.customOptions ?? {}
                    ).map((opt: { key: string; label: string }) => {
                        return (
                            <div>
                                <label>
                                    <Stack
                                        direction="horizontal"
                                        gap={2}
                                        style={{ alignItems: 'center' }}
                                    >
                                        <input
                                            checked={!!selectedCustomOptions[opt.key]}
                                            className="form-check-input"
                                            disabled={state.pendingResponse}
                                            name={'chk_' + opt.key}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                handleCustomOptionChange(opt.key, e.target.checked)
                                            }
                                            style={{ marginTop: 0 }}
                                            type="checkbox"
                                        />
                                        <span>{opt.label}</span>
                                    </Stack>
                                </label>
                            </div>
                        );
                    })}

                    <div>
                        <label>
                            <Stack direction="horizontal" gap={2} style={{ alignItems: 'center' }}>
                                <input
                                    checked={state.saveReport ? true : false}
                                    className="form-check-input"
                                    disabled={state.pendingResponse}
                                    name="chkSaveReport"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setState((prev) => ({
                                            ...prev,
                                            saveReport: e.target.checked,
                                        }));
                                    }}
                                    style={{ marginTop: 0 }}
                                    type="checkbox"
                                />
                                <span>Save Report</span>
                            </Stack>
                        </label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Stack
                    direction="horizontal"
                    style={{ width: '100%', justifyContent: 'space-between' }}
                >
                    <div className="text-danger">{validation.export}</div>
                    <div>
                        <button
                            className="btn btn-default"
                            onClick={() => {
                                if (typeof props.onClose === 'function') {
                                    props.onClose();
                                }
                            }}
                            disabled={state.pendingResponse}
                        >
                            <>{props.cancelbuttonText ?? 'Cancel'}</>
                        </button>
                        <button
                            className="btn btn-orange"
                            onClick={generateAndDownloadReport}
                            disabled={state.pendingResponse}
                        >
                            {state.pendingResponse ? (
                                <>
                                    <i className="fal fa-spinner fa-solid fa-spin" />
                                    &nbsp;Exporting
                                </>
                            ) : (
                                <>{props.submitButtonText ?? 'Run'}</>
                            )}
                        </button>
                    </div>
                </Stack>
            </Modal.Footer>
        </Modal>
    );
}
