import { HubConnection } from '@microsoft/signalr';
import { IUserModel } from '../../../../interfaces/IUser';
import { SideNavMenu } from './SideNavMenu';
import { useLocation } from 'react-router-dom';

interface ISideNavMenuProps {
    user?: IUserModel;
    isAuthenticated?: boolean;
    alertHubConnection: HubConnection | undefined;
    setIsLocalSwitchZone: () => void;
}

export default function SideNavMenuWrapper({
    user,
    isAuthenticated,
    alertHubConnection,
    setIsLocalSwitchZone,
}: ISideNavMenuProps) {
    const { key } = useLocation();

    return (
        <SideNavMenu
            user={user}
            isAuthenticated={isAuthenticated}
            alertHubConnection={alertHubConnection}
            locationKey={key}
            setIsLocalSwitchZone={setIsLocalSwitchZone}
        />
    );
}
