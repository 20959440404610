import {
    EntityTypes,
    NoteTypes,
    TimelineReportSections,
    UserRightsEnum,
} from '../../../../../utilities/Constants';

import Authorization from '../../../../../stores/Authorization';
import { INoteModel } from '../../../../../interfaces/INote';
import { IUserModel } from '../../../../../interfaces/IUser';
import { IZoneNoteModel } from '../../../../../interfaces/Report/ITimelineReport';
import { TextWithLineBreaks } from '../../../../../components/shared/TextWithLineBreaks';

interface IZoneNoteRowsProps {
    getCheckedValue: (
        section: TimelineReportSections,
        entityType: EntityTypes,
        guid: string
    ) => boolean;
    isPreviewMode: boolean;
    isReadOnly: boolean;
    onCheckedChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections,
        entityType: EntityTypes
    ) => void;
    onNoteEdit: (e: React.FormEvent<HTMLButtonElement>, noteItem: INoteModel) => void;
    previouslyViewedGuids: string[];
    user: IUserModel;
    zoneNotes: IZoneNoteModel[];
}

export function ZoneNoteRows(props: IZoneNoteRowsProps) {
    return (
        <>
            {props.zoneNotes.map((note) => {
                const previouslyViewed =
                    props.previouslyViewedGuids.filter((x) => x === note.noteGuid).length > 0;

                return (
                    <>
                        <tr>
                            {!props.isPreviewMode && (
                                <td className="border-0">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name={note.noteGuid}
                                        checked={props.getCheckedValue(
                                            TimelineReportSections.ZoneNotes,
                                            EntityTypes.Note,
                                            note.noteGuid
                                        )}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            props.onCheckedChange(
                                                e,
                                                TimelineReportSections.ZoneNotes,
                                                EntityTypes.Note
                                            );
                                        }}
                                        disabled={props.isReadOnly}
                                    />
                                </td>
                            )}
                            <td className="col-sm-6 border-0">{note.topic}</td>
                            <td className="border-0">{note.source}</td>
                            {Authorization.userHasRight(
                                UserRightsEnum.EditZoneNote,
                                props.user
                            ) && (
                                <td className="border-0" width={45}>
                                    {!props.isReadOnly && (
                                        <button
                                            className="btn btn-no-bg float-end"
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                props.onNoteEdit(e, {
                                                    guid: note.noteGuid,
                                                    type: { id: NoteTypes.ZoneNote },
                                                    status: { id: 0 },
                                                    purpose: { id: 0 },
                                                });
                                            }}
                                        >
                                            <i
                                                className={`fal fa-pen ${
                                                    previouslyViewed ? 'color-gray' : 'color-black'
                                                } `}
                                            />
                                        </button>
                                    )}
                                </td>
                            )}
                        </tr>
                        <tr>
                            <td />
                            <td colSpan={5}>
                                {note.noteContent && <TextWithLineBreaks text={note.noteContent} />}
                            </td>
                        </tr>
                    </>
                );
            })}
        </>
    );
}
