import { Navigate, Outlet } from 'react-router-dom';

import { Loader } from '../../shared/Loader';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { SecureLayout } from './SecureLayout';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useSignalRContext } from '../../../contexts/SignalRContext';

const SecureRoute = () => {
    const { isInitialized, isLoadingUser, isLoggedIn, user } = useAuthContext();
    const { alertHub } = useSignalRContext();

    if (!isInitialized && isLoadingUser) {
        return (
            <div style={{ background: 'white' }} className="login-background">
                Loading...
            </div>
        );
    }

    return isLoggedIn && isInitialized ? (
        <SecureLayout
            alertHubConnection={alertHub}
            isLoggedIn={isLoggedIn}
            user={user ?? { active: false }}
        >
            <Outlet />
            {isLoadingUser && <Loader />}
        </SecureLayout>
    ) : (
        <Navigate to={LocalRoutes.Login} />
    );
};

export default SecureRoute;
