import { AlertSubTypesEnum, AlertTypesEnum, EntityTypes } from '../../utilities/Constants';
import { useEffect, useState } from 'react';

import Common from '../../stores/Common';
import { IUpdateAlertUserModel } from '../../interfaces/Report/IReport';
import { IValidation } from '../../interfaces/IError';
import { Stack } from 'react-bootstrap';
import { useAuthContext } from '../../contexts/AuthContext';
import { useCloseAlertMutation } from './useCloseAlertMutation';
import { useGetCollaborationReportStatus } from './useGetCollaborationReportStatus';
import { useLoading } from '../../contexts/LoadingContext';

interface ICollabActHistoryProps {
    alertGuid: string;
    reportGuid: string;
}

export function CollabActHistory(props: ICollabActHistoryProps) {
    const auth = useAuthContext();
    const userStatuses = useGetCollaborationReportStatus(props.reportGuid);
    const closeChatActMutation = useCloseAlertMutation();
    const loading = useLoading();

    const [validation, setValidation] = useState<IValidation>({});

    const handleCollablActClick = async () => {
        if (!props.alertGuid) {
            setValidation((prev) => ({ ...prev, act: ['Failed to close Collab Act'] }));
        }

        const model = {
            alertGuid: props.alertGuid,
            alertType: AlertTypesEnum.Act,
            alertSubType: AlertSubTypesEnum.Collab,
            parentEntityGuid: props.reportGuid,
            parentEntityType: EntityTypes.Report,
        } as IUpdateAlertUserModel;

        loading.showLoading();

        await closeChatActMutation
            .mutateAsync(model)
            .then(() => {
                userStatuses.refetch();
            })
            .catch(() => {
                setValidation((prev) => ({ ...prev, closeAct: ['Failed to close Collab Act'] }));
            })
            .finally(() => {
                loading.hideLoading();
            });
    };

    useEffect(() => {
        userStatuses.refetch();
    }, []);

    if (userStatuses.isLoading) {
        return <div>Loading collaborators...</div>;
    }

    if ((userStatuses.data?.length ?? 0) === 0) {
        return null;
    }

    return (
        <div className="row">
            <div className="col-3" style={{ paddingRight: 0, paddingTop: 5 }}>
                <label className="control-label">Collab Act Status</label>
            </div>
            <div className="col-9">
                <table>
                    <tbody>
                        {userStatuses.data
                            ?.sort((a, b) =>
                                (a.user.profile?.firstName ?? '') <
                                (b.user.profile?.firstName ?? '')
                                    ? -1
                                    : 1
                            )
                            .map((collaborator) => {
                                if (!collaborator.user.profile) return null;

                                const { firstName, lastName } = collaborator.user.profile;
                                return (
                                    <tr
                                        key={collaborator.user.guid}
                                        style={{ verticalAlign: 'middle' }}
                                    >
                                        <td style={{ paddingRight: '1em', height: 33 }}>
                                            {firstName} {lastName}
                                        </td>
                                        <td>
                                            {collaborator.completed ? (
                                                <span style={{ paddingLeft: 11 }}>
                                                    Closed on{' '}
                                                    {Common.dateTimeFormat(
                                                        collaborator.dateCompleted
                                                    )}
                                                </span>
                                            ) : (
                                                <>
                                                    {auth.user.guid === collaborator.user.guid ? (
                                                        <button
                                                            className="btn btn-no-bg font-orange"
                                                            onClick={handleCollablActClick}
                                                        >
                                                            <Stack
                                                                direction="horizontal"
                                                                gap={1}
                                                                style={{ alignItems: 'center' }}
                                                            >
                                                                {closeChatActMutation.isLoading ? (
                                                                    <i className="fal fa-spinner fa-solid fa-spin" />
                                                                ) : (
                                                                    <i className="fal fa-check" />
                                                                )}
                                                                <span>Collab Act</span>
                                                            </Stack>
                                                        </button>
                                                    ) : (
                                                        <span
                                                            className="text-gray"
                                                            style={{ paddingLeft: 11 }}
                                                        >
                                                            Pending
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div className="text-danger mt-1">{validation.closeAct}</div>
        </div>
    );
}
