import { ApplicationHelpType, TimelineReportSections } from '../../../../../utilities/Constants';

import { Help } from '../../../../../components/shared/Help';
import { INoteModel } from '../../../../../interfaces/INote';
import { IWeeklyRecapSectionProps } from './IWeeklyRecapSectionProps';
import { IZoneNoteModel } from '../../../../../interfaces/Report/ITimelineReport';
import { SectionComment } from './SectionComment';
import { SectionHeader } from './SectionHeader';
import { ZoneNoteRows } from './ZoneNoteRows';

export interface IIndustryNewsSectionProps extends IWeeklyRecapSectionProps {
    data: IZoneNoteModel[];
    helpText: string;
    onNoteEdit: (e: React.FormEvent<HTMLButtonElement>, noteItem: INoteModel) => void;
    selectAllChecked: boolean;
}

export function IndustryNewsSection(props: IIndustryNewsSectionProps) {
    return (
        <>
            <SectionHeader
                checked={props.checked}
                helpComponent={
                    <Help
                        type={ApplicationHelpType.Info}
                        title="Industry News"
                        helpText={props.helpText}
                        additionalStyleClassNames="font-size-sm"
                    />
                }
                isPreviewMode={props.isPreviewMode}
                onCommentsButtonClick={props.onCommentsButtonClick}
                onSectionCheckedChange={props.onSectionCheckedChange}
                readonly={props.readonly}
                section={TimelineReportSections.ZoneNotes}
                title="Industry News"
            />

            {props.data.length > 0 && (
                <div className="margin-left-20">
                    <table className="table">
                        <thead>
                            <tr>
                                {!props.isPreviewMode && (
                                    <th style={{ width: 23 }}>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="selectAll"
                                            checked={props.selectAllChecked}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                props.onSelectAllCheckedChanged(
                                                    e,
                                                    TimelineReportSections.ZoneNotes
                                                );
                                            }}
                                            disabled={props.readonly}
                                        />
                                    </th>
                                )}
                                <th>Topic</th>
                                <th>Source</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <ZoneNoteRows
                                getCheckedValue={props.getCheckedValue}
                                zoneNotes={props.data}
                                previouslyViewedGuids={props.previouslyViewedGuids}
                                isPreviewMode={props.isPreviewMode}
                                isReadOnly={props.readonly}
                                user={props.user}
                                onCheckedChange={props.onCheckedChange}
                                onNoteEdit={props.onNoteEdit}
                            />
                        </tbody>
                    </table>
                </div>
            )}

            <SectionComment text={props.sectionComment} rowCount={props.data.length} />
        </>
    );
}
