import { Alert, Table } from 'react-bootstrap';
import {
    DocumentTypeEnum,
    ReportStatusOptionsList,
    reportTypeOptions,
} from '../../utilities/Constants';
import { IDocumentSearchModel, IViewDocumentModel } from '../../interfaces/IDocument';
import { useEffect, useState } from 'react';
import {
    useGetMyDocuments,
    useGetMyDocumentsCount,
    useGetMyDocumentsKey,
} from '../../shared/react-query-hooks/useGetMyDocuments';

import { ApiRoutes } from '../../utilities/ApiRoutes';
import { CheckmateDialog } from '../../components/shared/dialog';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import DocumentFilters from './DocumentFilter';
import DocumentRow from './DocumentRow';
import { DocumentsHeader } from './DocumentsHeader';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { ISortState } from '../../interfaces/ISortState';
import Pagination from '../../shared/Pagination';
import Sort from '../../stores/Sort';
import { cloneDeep } from 'lodash';
import { useCollabReportDeleteMutation } from './useCollabReportDeleteMutation';
import { useDocumentTypesLookup } from '../../shared/react-query-hooks/useDocumentTypesLookup';
import { useMyDocumentDeleteMutation } from './useMyDocumentDeleteMutation';
import { useQueryClient } from 'react-query';

const MyDocuments = () => {
    const queryClient = useQueryClient();

    const [sort, setSort] = useState<ISortState | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [filteredRows, setFilteredRows] = useState<IViewDocumentModel[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize] = useState<number>(100);
    const [deleteCallback, setDeleteCallback] = useState<() => void | undefined>();
    const [selectedDocumentTypeOptions, setSelectedDocumentTypeOptions] = useState<
        IMultiSelectOptions[]
    >([]);
    const [filteredReportTypeOptions, setFilteredReportTypeOptions] =
        useState<IMultiSelectOptions[]>(reportTypeOptions);
    const [selectedReportTypeOptions, setSelectedReportTypeOptions] = useState<
        IMultiSelectOptions[]
    >([]);
    const [selectedStatusOptions, setSelectedStatusOptions] = useState<IMultiSelectOptions[]>([]);
    const [selectedReadOption, setSelectedReadOption] = useState<IMultiSelectOptions>();

    const [documentSearchModel, setDocumentSearchModel] = useState<IDocumentSearchModel>({});

    const myDocumentsApi = useGetMyDocuments(documentSearchModel, pageNumber, pageSize, true);
    const { data: documentsCountResponse, refetch: refetchDocumentsCount } =
        useGetMyDocumentsCount(documentSearchModel);
    const [documentToDelete, setDocumentToDelete] = useState<IViewDocumentModel | null>(null);

    const documentTypesLookup = useDocumentTypesLookup();

    const deleteMutation = useMyDocumentDeleteMutation((errorMessage: string) =>
        setError(errorMessage)
    );

    const deleteCollabReportMutation = useCollabReportDeleteMutation((errorMessage: string) =>
        setError(errorMessage)
    );

    const downloadDocument = async (
        event: React.FormEvent<HTMLButtonElement>,
        documentGuid: string,
        documentName?: string
    ) => {
        event.preventDefault();
        const xhr = new XMLHttpRequest();
        const url = '/' + ApiRoutes.MyDocumentsDownload.replace('{id}', documentGuid);

        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/msword');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                let fileName = documentName ? documentName : 'EntityDocument.doc';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i].trim();
                            if (currentItem.includes('filename=')) {
                                fileName = currentItem.replace('filename=', '').replace(/"/g, '');
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                // TODO: display custom validation returned from the api call
                setError(DisplayMessages.DocumentDownloadError);
            }
        };

        xhr.send();
    };

    const executeDelete = (document: IViewDocumentModel) => {
        setError(undefined);

        const handleDeleteSuccess = () => {
            setDocumentToDelete(null);
            myDocumentsApi.refetch();
            refetchDocumentsCount();

            if (deleteCallback) {
                deleteCallback();
            }
        };

        if (document.isCollab) {
            deleteCollabReportMutation
                .mutateAsync(document.entityGuid!)
                .then(handleDeleteSuccess)
                .catch((error) => {
                    console.error(error);
                    setDocumentToDelete(null);
                });
        } else {
            deleteMutation
                .mutateAsync(document)
                .then(handleDeleteSuccess)
                .catch((error) => {
                    console.error(error);
                    setDocumentToDelete(null);
                });
        }
    };

    const clearFilter = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await resetView();
        runSearch();
    };

    const resetView = async () => {
        const documentSearchParametersCopy = cloneDeep(documentSearchModel);
        documentSearchParametersCopy.types = [];
        documentSearchParametersCopy.subTypes = [];
        documentSearchParametersCopy.isRead = undefined;
        documentSearchParametersCopy.statuses = [];
        documentSearchParametersCopy.startDate = '';
        documentSearchParametersCopy.endDate = '';

        setPageNumber(0);
        setSelectedDocumentTypeOptions([]);
        setSelectedReportTypeOptions([]);
        setSelectedReadOption(undefined);
        setSelectedStatusOptions([]);
        setFilteredReportTypeOptions(reportTypeOptions);
        setDocumentSearchModel(documentSearchParametersCopy);
        return new Promise<void>((resolve) => {
            resolve();
        });
    };

    const runSearch = (event?: React.FormEvent<HTMLButtonElement> | null) => {
        if (event) event.preventDefault();
        myDocumentsApi.refetch();
        refetchDocumentsCount();
    };

    const getSelectedFilters = () => {
        let selectedFilters = '';

        if (documentSearchModel.types?.length ?? 0 > 0) {
            selectedFilters += 'Types:\n';
            for (const type of documentSearchModel.types!) {
                selectedFilters += type.label + '\n';
            }
            selectedFilters += '\n';
        }

        if (documentSearchModel.subTypes?.length ?? 0 > 0) {
            selectedFilters += 'Sub Types:\n';
            for (const subType of documentSearchModel.subTypes!) {
                selectedFilters += subType.name + '\n';
            }
            selectedFilters += '\n';
        }

        if (documentSearchModel.statuses?.length ?? 0 > 0) {
            selectedFilters += 'Statuses:\n';
            for (const status of documentSearchModel.statuses!) {
                selectedFilters +=
                    ReportStatusOptionsList.find((opt) => opt.id === status)?.displayName + '\n';
            }
            selectedFilters += '\n';
        }

        if (documentSearchModel.isRead !== undefined) {
            selectedFilters += 'Read / Unread:\n';
            selectedFilters += documentSearchModel.isRead ? 'Read' : 'Unread' + '\n';
            selectedFilters += '\n';
        }

        if (documentSearchModel.startDate || documentSearchModel.endDate) {
            selectedFilters += 'Date Range:\n';

            if (documentSearchModel.startDate && !documentSearchModel.endDate) {
                selectedFilters += `${documentSearchModel.startDate} - Present`;
            } else if (!documentSearchModel.startDate && documentSearchModel.endDate) {
                selectedFilters += `Through ${documentSearchModel.endDate}`;
            } else {
                selectedFilters += `${documentSearchModel.startDate} - ${documentSearchModel.endDate}`;
            }
        }

        return selectedFilters;
    };

    const deleteMessage = (isCollab?: boolean, name?: string) => {
        const warning = 'This operation is permanent and cannot be undone.';
        if (isCollab === true) {
            return `Are you sure you want to delete the report titled '${name}'?\nAll documents/report data/alerts associated with this report will also be deleted.\n${warning}`;
        } else {
            return `Are you sure you want to delete the document titled '${name}'?\n${warning}`;
        }
    };

    // HANDLERS
    const handleSort = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        setSort({ pageNumber, key, order, subKey, subGrandKey });
    };

    const handleDocumentDeleteClick = async (
        documentToDelete: IViewDocumentModel,
        onSuccess?: () => void
    ) => {
        setDocumentToDelete(documentToDelete);
        if (typeof onSuccess === 'function') {
            setDeleteCallback(() => onSuccess);
        }
    };

    const handleDocumentDownloadClick = async (
        event: React.FormEvent<HTMLButtonElement>,
        documentToDownload: IViewDocumentModel
    ) => {
        const { guid, filename } = documentToDownload;
        const handleDownloadSuccess = () => {
            setFilteredRows((prev) => {
                const prevCopy = cloneDeep(prev);
                const found = prevCopy.find((row) => row.guid === guid);

                if (found) {
                    found.isRead = true;
                }
                return prevCopy;
            });
        };

        await downloadDocument(event, guid!, filename!)
            .then(handleDownloadSuccess)
            .catch((error) => {
                console.error(error);
            });
    };

    const handleDocumentViewClick = async (document: IViewDocumentModel) => {
        const documentsCopy = [...(cloneDeep(myDocumentsApi.data) ?? [])];
        const found = documentsCopy.find((doc) => doc.guid === document.guid);

        if (found) {
            found.isRead = true;
            queryClient.setQueryData(useGetMyDocumentsKey, documentsCopy);
        }
    };

    const handleDocumentTypeOptionSelected = (optionsList: IMultiSelectOptions[]) => {
        const documentSearchParametersCopy = cloneDeep(documentSearchModel);

        documentSearchParametersCopy.types = optionsList;
        setDocumentSearchModel(documentSearchParametersCopy);
        setSelectedDocumentTypeOptions(optionsList);

        let filteredOptions: IMultiSelectOptions[] = [...reportTypeOptions];

        if (optionsList.some((option) => option.id === 4)) {
            filteredOptions = [];
        }

        if (optionsList.some((option) => option.id === 5)) {
            const opt5FilterIds = [2, 14, 15, 23, 24, 16, 17, 19, 20, 26];
            const option5Filtered = filteredOptions.filter((opt) => opt5FilterIds.includes(opt.id));
            filteredOptions = Array.from(new Set(filteredOptions.concat(option5Filtered)));
        }

        if (optionsList.some((option) => option.id === 3)) {
            filteredOptions = [...reportTypeOptions];
        }

        setFilteredReportTypeOptions(filteredOptions);

        const typesWithSubTypes = [DocumentTypeEnum.Report];
        const onlyOneTypeIsSelected = (optionsList?.length ?? 0) === 1;

        const enableSubType =
            onlyOneTypeIsSelected && optionsList.some((opt) => typesWithSubTypes.includes(opt.id));

        if (!enableSubType) {
            setSelectedReportTypeOptions([]);
        }

        setDocumentSearchModel(documentSearchParametersCopy);
    };

    const handleReportTypeOptionSelected = (optionsList: IMultiSelectOptions[]) => {
        const documentSearchParametersCopy = cloneDeep(documentSearchModel);
        documentSearchParametersCopy.subTypes = optionsList.map((option) => ({
            id: option.id,
            guid: option.guid,
            name: option.value,
            displayName: option.label,
            alternateDisplayName: undefined,
            description: undefined,
            type: undefined,
        }));
        setDocumentSearchModel(documentSearchParametersCopy);
    };

    const handleStatusChange = (statuses: IMultiSelectOptions[]) => {
        setSelectedStatusOptions(statuses);
        const documentSearchParametersCopy = cloneDeep(documentSearchModel);
        documentSearchParametersCopy.statuses = statuses.map((opt) => opt.id);
        setDocumentSearchModel(documentSearchParametersCopy);
    };

    const handleReadChange = (optionalBoolean: IMultiSelectOptions | undefined) => {
        setSelectedReadOption(optionalBoolean);
        const documentSearchParametersCopy = cloneDeep(documentSearchModel);
        const valAsBool =
            optionalBoolean?.id === 1 ? true : optionalBoolean?.id === 0 ? false : undefined;
        documentSearchParametersCopy.isRead = valAsBool;
        setDocumentSearchModel(documentSearchParametersCopy);
    };

    const handleNextPage = () => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    };

    const handlePreviousPage = () => {
        setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 0));
    };

    const handleDateChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
        documentSearchModel: IDocumentSearchModel
    ) => {
        const { name, value } = event.target;

        const documentSearchModelCopy = cloneDeep(documentSearchModel);
        if (name === 'startDate') {
            const startDate = value;
            if (startDate !== '') {
                documentSearchModelCopy.validationError = Common.isValidDate(startDate)
                    ? ''
                    : 'Invalid Date';
            }
            documentSearchModelCopy.startDate = startDate;
        } else if (name === 'endDate') {
            const endDate = value;
            if (endDate !== '') {
                documentSearchModelCopy.validationError = Common.isValidDate(endDate)
                    ? ''
                    : 'Invalid Date';
            }
            documentSearchModelCopy.endDate = endDate;
        }

        if (documentSearchModelCopy.validationError?.length === 0) {
            setDocumentSearchModel(documentSearchModelCopy);
        }
    };

    // EFFECTS
    useEffect(() => {
        const documentsCopy = [...(cloneDeep(myDocumentsApi.data) ?? [])];
        if (sort) {
            documentsCopy.sort(
                Sort.compareValues(sort.key, sort.subKey, sort.order, sort.subGrandKey)
            );
        }
        setFilteredRows(documentsCopy);
    }, [sort, myDocumentsApi.data]);

    //apply if page number changes
    useEffect(() => {
        myDocumentsApi.refetch();
    }, [pageNumber]);

    return (
        <div className="container-fluid">
            <h1>My Documents</h1>
            {myDocumentsApi.isError && (
                <div className="row mb-1 me-2">
                    <Alert variant="error">{error}</Alert>
                </div>
            )}
            {error && (
                <div>
                    <span className="text-danger">{error}</span>
                </div>
            )}
            <DocumentFilters
                documentSearchModel={documentSearchModel}
                filteredReportTypeOptions={filteredReportTypeOptions}
                getSelectedFilters={getSelectedFilters}
                onClearFilter={clearFilter}
                onDateChange={handleDateChange}
                onDocumentTypeChange={handleDocumentTypeOptionSelected}
                onReadChange={handleReadChange}
                onReportTypeChange={handleReportTypeOptionSelected}
                onRunSearch={runSearch}
                onStatusChange={handleStatusChange}
                selectedDocumentTypeOptions={selectedDocumentTypeOptions}
                selectedReadOption={selectedReadOption}
                selectedReportTypeOptions={selectedReportTypeOptions}
                selectedStatusOptions={selectedStatusOptions}
            />
            <div>
                <Table responsive size="sm" className="mt-3">
                    <thead>
                        <DocumentsHeader onSort={handleSort} />
                    </thead>
                    <tbody>
                        {filteredRows.map((item: IViewDocumentModel) => (
                            <DocumentRow
                                key={item.guid}
                                document={item}
                                documentTypes={documentTypesLookup.data ?? []}
                                canDelete={true}
                                onDelete={handleDocumentDeleteClick}
                                onDownload={handleDocumentDownloadClick}
                                onViewClick={handleDocumentViewClick}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
            {myDocumentsApi.isSuccess && (
                <Pagination
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    count={documentsCountResponse?.data ?? 0}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                />
            )}

            {documentToDelete && (
                <CheckmateDialog
                    isShowingModal
                    body={deleteMessage(documentToDelete.isCollab, documentToDelete.filename)}
                    handleClose={() => setDocumentToDelete(null)}
                    handleConfirm={() => executeDelete(documentToDelete)}
                    confirmText="Delete"
                    cancelText="Cancel"
                    confirmButtonClassName="btn btn-black float-end"
                    closeButtonClassName="btn btn-default float-end"
                    size="lg"
                    title="Delete Document"
                />
            )}
        </div>
    );
};

export default MyDocuments;
