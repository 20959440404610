import { useLocation, useParams } from 'react-router-dom';

import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { IUserModel } from '../../interfaces/IUser';
import { Trends } from '../report/Trends';

function TrendsWrapper(props: { user: IUserModel }) {
    const { key } = useParams();
    const location = useLocation();
    return (
        <>
            <DocumentTitle title="Trends" />
            <Trends key={key || ''} user={props.user} refreshIdentifier={location.key} />
        </>
    );
}

export default TrendsWrapper;
