/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from 'react';

import {
    AlertListSearchStatusOptionsEnum,
    AlertListTypeEnum,
    AlertOriginTypesEnum,
    AlertStatusTypeEnum,
    AlertSubTypes,
    AlertSubTypesEnum,
    AlertTypes,
    AlertTypesEnum,
    ApplicationHelpType,
    CaseFileStaticItems,
    DataScopesEnum,
    EntityTypes,
    ListMode,
    MyAlertListSearchStatusMapping,
    MyAlertListSearchStatusOptions,
    MyAlertListSearchStatusOptionsMapping,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    ReportTypesEnum,
    StrategyTypes,
    TutorialTopics,
} from '../../utilities/Constants';
import {
    IAlertListModel,
    IAlertSearchModel,
    ICaseTriggerFieldModel,
    INoteCategoryTypeModel,
    INoteModel,
} from '../../interfaces/INote';
import { ICaseModel, ICaseTitleModel } from '../../interfaces/ICase';
import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';

import Auth from '../../stores/authentication';
import Authorization from '../../stores/Authorization';
import { CaseEasyUpdate } from '../case/CaseEasyUpdate';
import CheckmateApiClient from '../../services/apiClient';
import CheckmateNSelect from '../../components/shared/CheckmateNSelect';
import Common from '../../stores/Common';
import { Favorite } from '../../components/shared/Favorite';
import { Help } from '../../components/shared/Help';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { IFavoriteModel } from '../../interfaces/IFavorite';
import { IQueryFilterCriteria } from '../../interfaces/IQuery';
import { ITriggerRuleSettingsModel } from '../../interfaces/ITriggerRule';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import TriggerRuleHelper from '../../utilities/TriggerRuleHelper';
import ValidateUtils from '../../shared/validations';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';

const _apiClient = new CheckmateApiClient();

const PageSize = 100;
const availableTypeOptions: IMultiSelectOptions[] = [
    {
        id: AlertTypes.Act.Value,
        label: AlertTypes.Act.Name,
        value: AlertTypes.Act.Name,
    },
    {
        id: AlertTypes.Watch.Value,
        label: AlertTypes.Watch.Name,
        value: AlertTypes.Watch.Name,
    },
];

const availableSubTypeOptions: IMultiSelectOptions[] = [
    {
        id: AlertSubTypes.Collab.Value,
        label: AlertSubTypes.Collab.Name,
        value: AlertSubTypes.Collab.Name,
    },
    {
        id: AlertSubTypes.Chat.Value,
        label: AlertSubTypes.Chat.Name,
        value: AlertSubTypes.Chat.Name,
    },
];

interface IAlertListProps {
    user: IUserModel;
    alertListType: number;
    alertOriginTypes: ILookupModel[];
    alertStatusTypes: ILookupModel[];
    assertionDescriptors: IAssertionDescriptorModel[];
    defaultFavorite: IFavoriteModel | null;
    noteCategoryTypes: INoteCategoryTypeModel[];
    notePurposeTypes: ILookupModel[];
    noteTypes: ILookupModel[];
    users: IUserModel[];
    refreshIdentifier: string;
}

interface IAlertListState {
    pendingResponse: boolean;
    alertSearchParameters: IAlertSearchModel;
    validation: IValidation;

    noteTypes: IMultiSelectOptions[];
    types: IMultiSelectOptions[];
    subTypes: IMultiSelectOptions[];
    alertStatusTypes: IMultiSelectOptions[];
    users: IMultiSelectOptions[];
    purposes: IMultiSelectOptions[];
    alertOriginTypes: IMultiSelectOptions[];

    selectedAlertOriginTypesOptions: IMultiSelectOptions[];
    selectedMyAlertListStatusOptions: IMultiSelectOptions[];
    selectedAlertStatusOptions: IMultiSelectOptions[];
    selectedTypeOptions: IMultiSelectOptions[];
    selectedSubTypeOptions: IMultiSelectOptions[];
    selectedUserOptions: IMultiSelectOptions[];
    selectedNoteCategoryOptions: IMultiSelectOptions[];
    selectedNoteSubCategoryOptions: IMultiSelectOptions[];
    selectedPurposeOptions: IMultiSelectOptions[];

    alertList: IAlertListModel[];
    loggedInUserDataScopeId: number;
    openNoteEditor: boolean;
    currentNote: INoteModel;
    currentFavoriteDetailsString: string;
    userDefaultSettingsJson?: string;

    enableEasyUpdate: boolean;
    easyUpdateMode: boolean;
    easyUpdateCaseGuid?: string;
    easyUpdateAttemptedCaseList: string[];

    showTooManyRecordsToProcessDialog?: boolean;
    maxZoneAlertListCount: number;

    forceNoteEditorInReadOnlyMode?: boolean;
    allowEditToggle?: boolean;

    openCaseTriggerDetailPopup?: boolean;
    selectedCaseTriggerAlertGuid?: string;
}

export class AlertList extends React.Component<IAlertListProps, IAlertListState> {
    constructor(props: IAlertListProps) {
        super(props);

        this.state = {
            pendingResponse: true,
            alertSearchParameters: {},
            validation: {},
            noteTypes: [],
            types: [],
            subTypes: [],
            alertStatusTypes: [],
            alertOriginTypes: [],
            users: [],
            purposes: [],
            selectedAlertOriginTypesOptions: [],
            selectedMyAlertListStatusOptions: [],
            selectedAlertStatusOptions: [],
            selectedTypeOptions: [],
            selectedSubTypeOptions: [],
            selectedUserOptions: [],
            selectedNoteCategoryOptions: [],
            selectedNoteSubCategoryOptions: [],
            selectedPurposeOptions: [],
            alertList: [],
            loggedInUserDataScopeId: 0,
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
                type: { id: NoteTypes.CaseNote },
            },
            enableEasyUpdate: false,
            easyUpdateMode: false,
            easyUpdateAttemptedCaseList: [],
            currentFavoriteDetailsString: '',
            maxZoneAlertListCount: 0,
        };
    }

    defaultOriginTypes = [
        AlertOriginTypesEnum.CaseNote,
        AlertOriginTypesEnum.CaseTrigger,
        AlertOriginTypesEnum.PackageSettlementNote,
        AlertOriginTypesEnum.Report,
        AlertOriginTypesEnum.StrategyNote,
        AlertOriginTypesEnum.ZoneNote,
    ];

    componentDidMount() {
        if (Authorization.isAuthorizedToRoute(LocalRoutes.Alerts, this.props.user)) {
            if (
                this.props.alertListType === AlertListTypeEnum.MyAlertList ||
                this.props.alertListType === AlertListTypeEnum.ZoneAlertList
            ) {
                this.loadInitialLookupData();
                return;
            }
        }
        window.location.assign(LocalRoutes.AccessDenied);
    }

    componentDidUpdate(prevProps: IAlertListProps, prevState: IAlertListState) {
        if (prevProps.refreshIdentifier != this.props.refreshIdentifier)
            this.setState(
                {
                    pendingResponse: true,
                    validation: {},
                    types: [],
                    subTypes: [],
                    alertStatusTypes: [],
                    users: [],
                    purposes: [],
                    selectedAlertOriginTypesOptions: [],
                    selectedMyAlertListStatusOptions: [],
                    selectedAlertStatusOptions: [],
                    selectedTypeOptions: [],
                    selectedSubTypeOptions: [],
                    selectedUserOptions: [],
                    selectedNoteCategoryOptions: [],
                    selectedNoteSubCategoryOptions: [],
                    selectedPurposeOptions: [],
                    alertList: [],
                    //totalCount: 0,
                    loggedInUserDataScopeId: 0,
                    openNoteEditor: false,
                    currentNote: {
                        status: { id: NoteStatusTypes.Open },
                        purpose: { id: NotePurposeTypesEnum.Info },
                    },
                    enableEasyUpdate: false,
                    easyUpdateMode: false,
                    easyUpdateAttemptedCaseList: [],
                    currentFavoriteDetailsString: '',
                },
                () => this.loadInitialLookupData()
            );

        const isCaseNotesSelected = this.state.selectedAlertOriginTypesOptions.some(
            (opt) => opt.id === AlertOriginTypesEnum.CaseNote
        );

        const wasCaseNotesSelected = prevState.selectedAlertOriginTypesOptions.some(
            (opt) => opt.id === AlertOriginTypesEnum.CaseNote
        );

        const wasOnlyCaseNoteSelected =
            prevState.selectedAlertOriginTypesOptions.length === 1 && wasCaseNotesSelected;

        if (
            wasOnlyCaseNoteSelected &&
            (!isCaseNotesSelected || this.state.selectedAlertOriginTypesOptions.length > 1)
        ) {
            this.setState({
                selectedTypeOptions: [],
                selectedSubTypeOptions: [],
                selectedAlertStatusOptions: [],
                selectedNoteCategoryOptions: [],
                selectedNoteSubCategoryOptions: [],
                selectedPurposeOptions: [],
            });
        }

        if (isCaseNotesSelected && !wasCaseNotesSelected) {
            this.setState({ selectedUserOptions: [] });
        }
    }

    getDefaultOriginTypeOptions = () => {
        return this.state.alertOriginTypes;
    };

    getDefaultStatusOptions = () =>{
       return [
            { status: AlertStatusTypeEnum.Pending, read: true },
            { status: AlertStatusTypeEnum.Pending, read: false },
            { status: AlertStatusTypeEnum.Closed, read: false },
        ];
    }

    loadInitialLookupData = async () => {
        if (this.props.users.length === 0) return;

        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );

        const availableUserOptionsSorted =
            this.getSortedAvailableUserOptions(loggedInUserDataScopeId);

        if (!this.props.alertStatusTypes) return;

        const availableAlertStatusTypeOptions = this.props.alertStatusTypes.map((statusType) => ({
            id: statusType.id,
            label: statusType.displayName!,
            value: statusType.name!,
        }));

        if (!this.props.alertOriginTypes) return;

        const availableAlertOriginTypeOptions = this.props.alertOriginTypes.map(
            (currentAlertOriginType) => ({
                id: currentAlertOriginType.id,
                label: currentAlertOriginType.displayName!,
                value: currentAlertOriginType.name!,
            })
        );

        if (!this.props.notePurposeTypes) return;

        const availableNotePurposeTypeOptions = this.props.notePurposeTypes
            .filter((x) => x.id !== NotePurposeTypesEnum.Info)
            .map((notePurposeType) => ({
                id: notePurposeType.id,
                label: notePurposeType.displayName!,
                value: notePurposeType.name!,
            }));

        if (!this.props.noteTypes) return;

        const availableNoteTypeOptions: IMultiSelectOptions[] = this.props.noteTypes
            .filter(
                (x) => x.id !== NoteTypes.Personal && x.id !== NoteTypes.StrategySignificantChange
            )
            .map((item) => ({
                id: item.id,
                label: item.displayName!,
                value: item.name!,
            }));

        const userDefaultSettingsJson = this.props.defaultFavorite?.settingJson;

        this.setState(
            {
                loggedInUserDataScopeId,
                types: availableTypeOptions,
                subTypes: availableSubTypeOptions,
                users: availableUserOptionsSorted,
                purposes: availableNotePurposeTypeOptions,
                noteTypes: availableNoteTypeOptions,
                alertOriginTypes: availableAlertOriginTypeOptions,
                alertStatusTypes: availableAlertStatusTypeOptions,
                userDefaultSettingsJson,
                selectedAlertOriginTypesOptions: availableAlertOriginTypeOptions,
            },
            () => {
                this.loadAlertList(ListMode.UserDefault);
            }
        );
    };

    getSortedAvailableUserOptions = (loggedInUserDataScopeId: number) => {
        const defaultOrg = Auth.getUserDefaultOrganization();
        let availableUserOptions: IMultiSelectOptions[] = [];

        if (defaultOrg) {
            const isLocalBasicWithHomeOrg =
                loggedInUserDataScopeId === DataScopesEnum.LocalBasic.Value &&
                this.props.user.homeOrganization;

            if (isLocalBasicWithHomeOrg) {
                const nationalUsers = this.props.users
                    .filter(
                        (user) =>
                            Authorization.getUserDefaultOrganizationDataScope(user) ===
                            DataScopesEnum.National.Value
                    )
                    .map((user) => ({
                        label:
                            user.profile!.firstName +
                            ' ' +
                            user.profile!.lastName +
                            (user.activeZoneDataScope &&
                            user.activeZoneDataScope!.id === DataScopesEnum.LocalBasic.Value
                                ? ' (Local)'
                                : ''),
                        value: user.guid!,
                        id: 0,
                        guid: user.guid,
                    }));

                const homeOrgUsers = this.props.users
                    .filter(
                        (x) =>
                            x.homeOrganization &&
                            this.props.user.homeOrganization &&
                            x.homeOrganization.guid === this.props.user.homeOrganization.guid
                    )
                    .map((user: IUserModel) => ({
                        label:
                            user.profile!.firstName +
                            ' ' +
                            user.profile!.lastName +
                            (user.activeZoneDataScope &&
                            user.activeZoneDataScope.id === DataScopesEnum.LocalBasic.Value
                                ? ' (Local)'
                                : ''),
                        value: user.guid!,
                        id: 0,
                        guid: user.guid,
                    }));

                availableUserOptions = nationalUsers.concat(homeOrgUsers);
            } else {
                for (let i = 0; i < this.props.users.length; i++) {
                    const currentUser = this.props.users[i];
                    availableUserOptions.push({
                        label:
                            currentUser.profile!.firstName +
                            ' ' +
                            currentUser.profile!.lastName +
                            (currentUser.activeZoneDataScope &&
                            currentUser.activeZoneDataScope!.id === DataScopesEnum.LocalBasic.Value
                                ? ' (Local)'
                                : ''),
                        value: currentUser.guid!,
                        guid: currentUser.guid!,
                        id: 0,
                    });
                }
            }
        }
        const availableUserOptionsSorted = availableUserOptions.sort(Sort.compareValues('label'));
        return availableUserOptionsSorted;
    };

    getNoteCategoryTypeOptions = () => {
        const noteTypeId = NoteTypes.CaseNote; // Temporarily only Case Notes.
        const noteCategoryTypeOptions = this.props.noteCategoryTypes
            .filter((x) => x.noteType.id === noteTypeId)
            .map((cat: ILookupModel) => ({
                label: cat.displayName!,
                value: cat.name!,
                id: cat.id,
            }));

        return noteCategoryTypeOptions.sort(Sort.compareValues('label'));
    };

    getNoteSubCategoryTypeOptions = () => {
        const noteSubCategoryTypeOptions = this.state.selectedNoteCategoryOptions
            .flatMap((cat) => {
                const match = this.props.noteCategoryTypes.find((x) => x.id === cat.id);
                return match?.subCategories ?? [];
            })
            .map((item) => ({
                label: item.displayName!,
                value: item.name!,
                id: item.id,
                parentId: this.props.noteCategoryTypes.find((x) =>
                    x.subCategories?.some((sub) => sub.id === item.id)
                )?.id,
            }));

        return noteSubCategoryTypeOptions.sort(Sort.compareValues('label'));
    };

    markCaseTriggerAsRead = async (id: string, openCaseTriggerDetailPopup?: boolean) => {
        this.setState({ pendingResponse: true });

        const alertListCopy = cloneDeep(this.state.alertList);
        const match = alertListCopy.find((x) => x.guid === id);

        if (match && !match?.alertReadForLoggedInUser) {
            const response = await _apiClient.markAlertAsRead(id);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                    pendingResponse: false,
                });
                return;
            }

            match.alertReadForLoggedInUser = true;
            match.status = 'Closed';
        }

        this.setState({
            pendingResponse: false,
            alertList: alertListCopy,
            openCaseTriggerDetailPopup: openCaseTriggerDetailPopup,
            selectedCaseTriggerAlertGuid: id,
        });
    };

    handleMyAlertListStatusFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParametersCopy = cloneDeep(this.state.alertSearchParameters);
        alertSearchParametersCopy.statuses = optionsList
            .map((item) => MyAlertListSearchStatusMapping.find((x) => x.id === item.id) ?? null)
            .filter((status) => status)
            .map((status) => ({
                status: status!.AlertStatusType,
                read: status!.Read,
            }));

        this.setState({
            selectedMyAlertListStatusOptions: optionsList,
            alertSearchParameters: alertSearchParametersCopy,
        });
    };

    handleAlertOriginTypeFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParametersCopy = cloneDeep(this.state.alertSearchParameters);
        alertSearchParametersCopy.originTypes = optionsList.map((item) => item.id);

        const hasReportAlertOriginType = optionsList.some(
            (opt) => opt.id === AlertOriginTypesEnum.Report
        );
        const hasCaseTriggerAlertOriginType = optionsList.some(
            (opt) => opt.id === AlertOriginTypesEnum.CaseTrigger
        );

        let types: IMultiSelectOptions[] = [];
        if (hasReportAlertOriginType) {
            types = availableTypeOptions.filter((type) => type.id !== AlertTypesEnum.Watch);
            this.setState({
                types,
                selectedTypeOptions: this.state.selectedTypeOptions.filter(
                    (opt) => opt.id !== AlertTypesEnum.Watch
                ),
            });
        } else {
            this.setState({
                types: availableTypeOptions,
            });
        }

        if (hasCaseTriggerAlertOriginType) {
            this.setState({ selectedTypeOptions: [], selectedSubTypeOptions: [] });
        }

        this.setState({
            selectedAlertOriginTypesOptions: optionsList,
            alertSearchParameters: alertSearchParametersCopy,
        });
    };

    onAlertStatusFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParametersCopy = cloneDeep(this.state.alertSearchParameters);

        alertSearchParametersCopy.statuses = optionsList.map((item: IMultiSelectOptions) => ({
            status: item.id!,
        }));

        this.setState({ selectedAlertStatusOptions: optionsList });
    };

    onTypeFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParametersCopy = cloneDeep(this.state.alertSearchParameters);

        alertSearchParametersCopy.alertTypeIds = optionsList.map(
            (item: IMultiSelectOptions) => item.id
        );

        this.setState({ selectedTypeOptions: optionsList, 
            alertSearchParameters: alertSearchParametersCopy,
         });
    };

    onSubTypeFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParameters = this.state.alertSearchParameters;
        alertSearchParameters.alertSubTypeIds = [];
        if (optionsList.length > 0) {
            optionsList.map((item: IMultiSelectOptions) => {
                alertSearchParameters.alertSubTypeIds!.push(item.id);
            });
        }
        this.setState({ selectedSubTypeOptions: optionsList });
    };

    onUserFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParametersCopy = cloneDeep(this.state.alertSearchParameters);

        alertSearchParametersCopy.alertUserGuids = optionsList.map(
            (item: IMultiSelectOptions) => item.guid!
        );

        this.setState({
            selectedUserOptions: optionsList,
            alertSearchParameters: alertSearchParametersCopy,
        });
    };

    onNoteCategoryFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParametersCopy = cloneDeep(this.state.alertSearchParameters);

        alertSearchParametersCopy.noteCategories = optionsList.map(
            (item: IMultiSelectOptions) => item.id
        );

        this.setState({
            selectedNoteCategoryOptions: optionsList,
            alertSearchParameters: alertSearchParametersCopy,
        });
    };

    onNoteSubCategoryFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParametersCopy = cloneDeep(this.state.alertSearchParameters);

        alertSearchParametersCopy.noteSubCategories = optionsList.map(
            (item: IMultiSelectOptions) => item.id
        );

        this.setState({
            selectedNoteSubCategoryOptions: optionsList,
            alertSearchParameters: alertSearchParametersCopy,
        });
    };

    onPurposeFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParameters = cloneDeep(this.state.alertSearchParameters);

        alertSearchParameters.purpose = optionsList.map((item: IMultiSelectOptions) => item.id);

        this.setState({
            selectedPurposeOptions: optionsList,
            alertSearchParameters: alertSearchParameters,
        });
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let alertList = cloneDeep(this.state.alertList);
        console.log(alertList, { key, order, subKey });
        if (alertList) {
            if (key.includes('date')) {
                alertList = alertList.sort(Sort.compareDate(key, subKey, order));
            } else {
                alertList = alertList.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }

        this.setState({ alertList });
        return;
    };

    loadAlertList = async (mode: ListMode, settingsJson?: string, isDefaultSetting?: boolean) => {
        const validation: IValidation = {};
        let selectedMyAlertListStatusOptions = this.state.selectedMyAlertListStatusOptions;
        let selectedAlertOriginTypesOptions = this.state.selectedAlertOriginTypesOptions;
        let selectedTypeOptions = this.state.selectedTypeOptions;
        let selectedSubTypeOptions = this.state.selectedSubTypeOptions;
        let selectedUserOptions = this.state.selectedUserOptions;
        let selectedNoteCategoryOptions = this.state.selectedNoteCategoryOptions;
        let selectedNoteSubCategoryOptions = this.state.selectedNoteSubCategoryOptions;
        let selectedPurposeOptions = this.state.selectedPurposeOptions;
        let selectedAlertStatusOptions = this.state.selectedAlertStatusOptions;
        let alertSearchParameters = this.state.alertSearchParameters;
        let userDefaultSettingsJson = this.state.userDefaultSettingsJson;

        if (mode === ListMode.RunSearch) {
            if (
                this.props.alertListType === AlertListTypeEnum.ZoneAlertList &&
                this.state.selectedTypeOptions.length > 0 &&
                this.state.selectedUserOptions.length === 0
            ) {
                validation.model = ['Please select a User in order to search by Alert Type'];
                this.setState({ validation: validation });
                return;
            }

            if (
                this.state.selectedAlertOriginTypesOptions.length === 0 &&
                this.state.selectedMyAlertListStatusOptions.length === 0 &&
                this.state.selectedTypeOptions.length === 0 &&
                this.state.selectedSubTypeOptions.length === 0 &&
                this.state.selectedUserOptions.length === 0 &&
                this.state.selectedNoteCategoryOptions.length === 0 &&
                this.state.selectedNoteSubCategoryOptions.length === 0 &&
                this.state.selectedPurposeOptions.length === 0 &&
                this.state.selectedAlertStatusOptions.length === 0
            ) {
                validation.model = ['Enter at least one filter criteria'];
                this.setState({ validation: validation });
                return;
            }

            if (this.state.selectedAlertOriginTypesOptions.length == 0) {
                validation.model = ['Enter at least one type to filter'];
                this.setState({ validation: validation });
                return;
            }
        } else if (
            mode === ListMode.FavoriteSelected ||
            (mode === ListMode.UserDefault && this.state.userDefaultSettingsJson)
        ) {
            settingsJson =
                mode === ListMode.FavoriteSelected
                    ? settingsJson
                    : this.state.userDefaultSettingsJson!;

            selectedMyAlertListStatusOptions = [];
            selectedAlertOriginTypesOptions = [];
            selectedTypeOptions = [];
            selectedSubTypeOptions = [];
            selectedUserOptions = [];
            selectedNoteCategoryOptions = [];
            selectedNoteSubCategoryOptions = [];
            selectedPurposeOptions = [];
            selectedAlertStatusOptions = [];

            //if (currentAlertSearchParameterString.trim() === settingJson.trim()) return;
            alertSearchParameters = JSON.parse(settingsJson!);

            if (alertSearchParameters.queryModel) {
                alertSearchParameters.queryModel.pageNumber = 0;
                alertSearchParameters.queryModel.pageSize = PageSize;
            }

            if (isDefaultSetting) userDefaultSettingsJson = settingsJson;

            // Populate all the filters with the filter values from the Favorite that was just selected.
            // few users OriginTypes & statuses are not set in the favourite settings.
            if(!alertSearchParameters.originTypes){
                alertSearchParameters.originTypes = this.defaultOriginTypes;
            }

            if (alertSearchParameters.originTypes) {
                selectedAlertOriginTypesOptions = this.state.alertOriginTypes.filter(
                    (alertOriginType) =>
                        alertSearchParameters.originTypes?.includes(alertOriginType.id)
                );
            }

            if(!alertSearchParameters.statuses){
                alertSearchParameters.statuses = this.getDefaultStatusOptions();
            }

            if (alertSearchParameters.statuses) {
                for (let i = 0; i < alertSearchParameters.statuses.length; i++) {
                    const element = alertSearchParameters.statuses![i];
                    if (this.props.alertListType === AlertListTypeEnum.MyAlertList) {
                        const mappingMatch = MyAlertListSearchStatusMapping.filter(
                            (x) => x.AlertStatusType === element.status && x.Read === element.read
                        );
                        if (mappingMatch.length > 0) {
                            const statusOptionsMatch = MyAlertListSearchStatusOptions.find(
                                (x) => x.id === mappingMatch[0].id
                            );
                            if (statusOptionsMatch)
                                selectedMyAlertListStatusOptions.push(statusOptionsMatch);
                        }
                    } else if (this.props.alertListType === AlertListTypeEnum.ZoneAlertList) {
                        const alertStatusOptionMatch = this.state.alertStatusTypes.find(
                            (x) => x.id === element.status
                        );
                        if (
                            selectedMyAlertListStatusOptions.filter((x) => x.id === element.status)
                                .length === 0 &&
                            alertStatusOptionMatch
                        )
                            selectedMyAlertListStatusOptions.push(alertStatusOptionMatch);
                    }
                }
            }

            selectedTypeOptions =
                alertSearchParameters.alertTypeIds
                    ?.filter((id) => this.state.types.map((type) => type.id).includes(id))
                    .map((id) => this.state.types.find((x) => x.id === id)!) ?? [];

            selectedSubTypeOptions =
                alertSearchParameters.alertSubTypeIds
                    ?.filter((id) => this.state.subTypes.map((subtype) => subtype.id).includes(id))
                    .map((id) => this.state.subTypes.find((x) => x.id === id)!) ?? [];

            selectedUserOptions =
                alertSearchParameters.alertUserGuids
                    ?.filter((guid) => this.state.users.map((user) => user.guid).includes(guid))
                    .map((guid) => this.state.users.find((user) => user.guid === guid)!) ?? [];

            selectedNoteCategoryOptions =
                this.props.noteCategoryTypes
                    ?.filter((category) =>
                        alertSearchParameters.noteCategories?.includes(category.id)
                    )
                    .map((category) => ({
                        value: category.name!,
                        label: category.displayName ?? category.name!,
                        id: category.id,
                    })) ?? [];

            selectedNoteSubCategoryOptions = this.props.noteCategoryTypes
                .flatMap((category) =>
                    category.subCategories
                        ? category.subCategories.filter((subCategory) =>
                              alertSearchParameters.noteSubCategories?.includes(subCategory.id)
                          )
                        : []
                )
                .filter((subCategory) => subCategory) // Filter out any empty or undefined subCategory values
                .map((subCategory) => ({
                    value: subCategory.name!,
                    label: subCategory.displayName ?? subCategory.name!,
                    id: subCategory.id,
                }));

            selectedPurposeOptions = (alertSearchParameters.purpose ?? [])
                .filter((purposeId) =>
                    this.state.purposes.map((purpose) => purpose.id).includes(purposeId)
                )
                .map(
                    (purposeId) => this.state.purposes.find((purpose) => purpose.id === purposeId)!
                );
        } else {
            // Checkmate Default View
            selectedTypeOptions = [];
            selectedUserOptions = [];
            selectedSubTypeOptions = [];
            selectedNoteCategoryOptions = [];
            selectedNoteSubCategoryOptions = [];
            selectedPurposeOptions = [];
            selectedAlertStatusOptions = [];
            selectedAlertOriginTypesOptions = this.getDefaultOriginTypeOptions();

            alertSearchParameters = {
                alertListType: this.props.alertListType,
                originTypes: this.defaultOriginTypes,
                queryModel: { pageSize: PageSize, pageNumber: 0, sortBy: 0, sortDirection: 0 },
            };

            if (this.props.alertListType === AlertListTypeEnum.ZoneAlertList) {
                alertSearchParameters.statuses = [{ status: AlertStatusTypeEnum.Pending }];
                selectedAlertStatusOptions = this.state.alertStatusTypes.filter(
                    (x) => x.id === AlertStatusTypeEnum.Pending
                );
            } else {
                alertSearchParameters.statuses = this.getDefaultStatusOptions();
                selectedMyAlertListStatusOptions = MyAlertListSearchStatusOptions.filter(
                    (x) =>
                        x.id === AlertListSearchStatusOptionsEnum.OpenRead ||
                        x.id === AlertListSearchStatusOptionsEnum.OpenUnread ||
                        x.id === AlertListSearchStatusOptionsEnum.ResolvedUnread
                );
            }
        }

        this.setState({ pendingResponse: true });

        const response = await _apiClient.searchAlerts(alertSearchParameters);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        const alertsWithActionTarget =
            response.payload?.data.map((alert) => {
                let actionTargetName = '';
                if (alert.originTypeId === AlertOriginTypesEnum.Report && this.props.user.profile) {
                    const { firstName, lastName } = this.props.user.profile;
                    actionTargetName = `${firstName} ${lastName}`;
                }

                return {
                    ...alert,
                    actionTargetName,
                };
            }) ?? [];

        this.setState({
            alertSearchParameters: {
                ...alertSearchParameters,
                alertListType: this.props.alertListType,
                originTypes: selectedAlertOriginTypesOptions.map((opt) => opt.id),
            },
            alertList: alertsWithActionTarget,
            maxZoneAlertListCount: response.payload ? response.payload.maxLimit : 0,
            showTooManyRecordsToProcessDialog:
                response.payload && response.payload.count > response.payload.maxLimit
                    ? true
                    : false,
            pendingResponse: false,
            validation: validation,
            selectedMyAlertListStatusOptions,
            selectedTypeOptions,
            selectedSubTypeOptions,
            selectedUserOptions,
            selectedNoteCategoryOptions,
            selectedNoteSubCategoryOptions,
            selectedPurposeOptions,
            selectedAlertStatusOptions,
            userDefaultSettingsJson,
            selectedAlertOriginTypesOptions,
        });
    };

    getCaseTitle = () => {
        const caseTitle: ICaseTitleModel = {};
        if (this.state.currentNote.guid) {
            const match = this.state.alertList.find(
                (x) => x.originEntitySubKey === this.state.currentNote.guid
            );
            if (match) {
                caseTitle.caseName = match.originEntityName;
                caseTitle.priority = match.priority;
                caseTitle.SQScore = match.sqScore;
                caseTitle.SQProjection = match.sqProjection;
                caseTitle.guid = match.originEntityKey;
            }
        }

        return caseTitle;
    };

    getCaseLocalCounselOrgGuid = () => {
        if (this.state.currentNote.guid) {
            const match = this.state.alertList.find(
                (x) => x.originEntitySubKey === this.state.currentNote.guid
            );
            if (match) {
                return match.localCounselOrganizationGuid;
            }
        }
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        const alertList = [...this.state.alertList];

        const foundIdx = alertList.findIndex((listItem) => listItem.guid === noteItem.alert?.guid);
        if (foundIdx > -1) {
            const note = JSON.parse(JSON.stringify(noteItem)) as INoteModel;

            const newAlertObj = {
                guid: noteItem.alert?.guid,
                originTypeId: noteItem.alert?.originType?.id,
                originType:
                    noteItem.alert?.originType?.displayName ??
                    noteItem.alert?.originType?.name ??
                    '',
                actionTargetName: 'actionTargetName - TO DO',
                originEntityKey: note.caseGuid,
                originEntityName: note.caseName,
                originEntitySubKey: note.guid,
                originSubEntityType: EntityTypes.Note,
                decision: note.decisionText,
                details: note.categories?.map((cat) => cat.displayName).join('\n'),
                purpose: note.purpose.displayName,
                modifiedDate: note.createdDate,
                writer: `${note.author?.profile?.firstName} ${note.author?.profile?.lastName}`,
                status: noteItem.alert?.status?.displayName ?? noteItem.alert?.status?.name ?? '',
                alertReadForLoggedInUser: note.alertReadForLoggedInUser,
                sqScore: note.qScore,
                isLoggedInUserWatchUser: note.watchUser,
                allowEdit: NoteHelper.allowNoteEdit(note, this.props.user),
                localCounselOrganizationGuid: note.localCounselOrganizationGuid,
            } as IAlertListModel;

            alertList[foundIdx] = newAlertObj;
        }

        this.setState({
            pendingResponse: false,
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            alertList,
        });
    };

    handleNoteEditorCancel = () => {
        this.setState({
            currentNote: { status: { id: 0 }, purpose: { id: NotePurposeTypesEnum.Info } },
            openNoteEditor: false,
        });
    };

    handleFavoriteSelected = (settingJson?: string) => {
        let selectedFilters = '';
        if (settingJson) {
            const alertSearchParameters: IAlertSearchModel = JSON.parse(settingJson!);

            if (alertSearchParameters.originTypes && alertSearchParameters.originTypes.length > 0) {
                selectedFilters += 'TYPE:' + '\n';
                for (let i = 0; i < alertSearchParameters.originTypes.length; i++) {
                    const match = this.state.alertOriginTypes.filter(
                        (x) => x.id == alertSearchParameters.originTypes![i]
                    );
                    if (match.length > 0) selectedFilters += match[0].label + '\n';
                }
                selectedFilters += '\n';
            }

            if (alertSearchParameters.statuses && alertSearchParameters.statuses.length > 0)
                selectedFilters += 'STATUS:' + '\n';
            {
                if (this.props.alertListType === AlertListTypeEnum.MyAlertList) {
                    for (let i = 0; i < alertSearchParameters.statuses!.length; i++) {
                        const element = alertSearchParameters.statuses![i];
                        const id = MyAlertListSearchStatusOptionsMapping.find(
                            (x) => x.status == element.status && x.read == element.read
                        )?.id;
                        const match = MyAlertListSearchStatusOptions.find((x) => x.id === id);

                        if (match) selectedFilters += match.label + '\n';
                    }
                    selectedFilters += '\n';
                } else if (this.props.alertListType === AlertListTypeEnum.ZoneAlertList) {
                    for (let i = 0; i < alertSearchParameters.statuses!.length; i++) {
                        const match = this.state.alertStatusTypes.filter(
                            (x) => x.id == alertSearchParameters.statuses![i].status
                        );
                        if (match.length > 0) selectedFilters += match[0].label + '\n';
                    }
                    selectedFilters += '\n';
                }
            }

            if (
                alertSearchParameters.alertTypeIds &&
                alertSearchParameters.alertTypeIds.length > 0
            ) {
                selectedFilters += 'Alert Type:' + '\n';
                for (let i = 0; i < alertSearchParameters.alertTypeIds.length; i++) {
                    const match = this.state.types.filter(
                        (x) => x.id == alertSearchParameters.alertTypeIds![i]
                    );
                    if (match.length > 0) selectedFilters += match[0].label + '\n';
                }
                selectedFilters += '\n';
            }

            if (
                alertSearchParameters.alertSubTypeIds &&
                alertSearchParameters.alertSubTypeIds.length > 0
            ) {
                selectedFilters += 'Alert Sub Type:' + '\n';
                for (let i = 0; i < alertSearchParameters.alertSubTypeIds.length; i++) {
                    const match = this.state.subTypes.filter(
                        (x) => x.id == alertSearchParameters.alertSubTypeIds![i]
                    );
                    if (match.length > 0) selectedFilters += match[0].label + '\n';
                }
                selectedFilters += '\n';
            }

            if (
                alertSearchParameters.alertUserGuids &&
                alertSearchParameters.alertUserGuids.length > 0
            ) {
                selectedFilters += 'USERS:' + '\n';
                for (let i = 0; i < alertSearchParameters.alertUserGuids.length; i++) {
                    const match = this.state.users.filter(
                        (x) => x.guid == alertSearchParameters.alertUserGuids![i]
                    );
                    if (match.length > 0) selectedFilters += match[0].label + '\n';
                }
                selectedFilters += '\n';
            }

            if (
                alertSearchParameters.noteCategories &&
                alertSearchParameters.noteCategories.length > 0
            ) {
                selectedFilters += 'NOTE CATEGORIES:' + '\n';
                for (let i = 0; i < alertSearchParameters.noteCategories.length; i++) {
                    const match = this.props.noteCategoryTypes.find(
                        (x) => x.id === alertSearchParameters.noteCategories![i]
                    );
                    if (match) selectedFilters += match.displayName + '\n';
                }
                selectedFilters += '\n';
            }

            if (
                alertSearchParameters.noteSubCategories &&
                alertSearchParameters.noteSubCategories.length > 0
            ) {
                selectedFilters += 'NOTE SUB-CATEGORIES:' + '\n';
                for (let i = 0; i < alertSearchParameters.noteSubCategories.length; i++) {
                    for (let index = 0; index < this.props.noteCategoryTypes.length; index++) {
                        const element = this.props.noteCategoryTypes[index];
                        if (element.subCategories) {
                            const match = element.subCategories.find(
                                (x) => x.id === alertSearchParameters.noteSubCategories![i]
                            );
                            if (match) selectedFilters += match.displayName + '\n';
                        }
                    }
                }
                selectedFilters += '\n';
            }
            if (alertSearchParameters.purpose && alertSearchParameters.purpose.length > 0) {
                selectedFilters += 'PURPOSE:' + '\n';
                for (let i = 0; i < alertSearchParameters.purpose.length; i++) {
                    const match = this.state.purposes.filter(
                        (x) => x.id == alertSearchParameters.purpose![i]
                    );
                    if (match.length > 0) selectedFilters += match[0].label + '\n';
                }
                selectedFilters += '\n';
            }
        }

        this.setState({ currentFavoriteDetailsString: selectedFilters });
    };

    applyFavoriteSelected = (settingJson?: string, isDefault?: boolean) => {
        if (settingJson) {
            this.loadAlertList(ListMode.FavoriteSelected, settingJson, isDefault);
        }
    };

    handleDefaultFavoriteChange = (settingJson?: string) => {
        this.setState({ userDefaultSettingsJson: settingJson });
    };

    nextPage = async () => {
        const alertSearchParameters = this.state.alertSearchParameters;
        if (alertSearchParameters.queryModel)
            alertSearchParameters.queryModel.pageNumber =
                alertSearchParameters.queryModel.pageNumber + 1;
        this.setState({ alertSearchParameters: alertSearchParameters }, () => {
            this.loadAlertList(ListMode.RunSearch);
        });
    };

    previousPage = async () => {
        const alertSearchParameters = this.state.alertSearchParameters;
        if (alertSearchParameters.queryModel)
            alertSearchParameters.queryModel.pageNumber =
                alertSearchParameters.queryModel.pageNumber - 1;
        this.setState({ alertSearchParameters: alertSearchParameters }, () => {
            this.loadAlertList(ListMode.RunSearch);
        });
    };

    handleEasyUpdate = (e: React.FormEvent<HTMLButtonElement>, alertListItem: IAlertListModel) => {
        const easyUpdateAttemptedCaseList = this.state.easyUpdateAttemptedCaseList;
        const caseGuid = alertListItem.originEntityKey!;
        const currentNote = this.state.currentNote;
        currentNote.type = { id: this.getNoteType(alertListItem) };

        easyUpdateAttemptedCaseList.push(caseGuid);
        this.setState({
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList: easyUpdateAttemptedCaseList,
            currentNote: currentNote,
        });
    };

    easyUpdateAttempted = (caseGuid: string) => {
        return this.state.easyUpdateAttemptedCaseList.filter((x) => x == caseGuid).length > 0;
    };

    easyUpdateDone = (updatedCase: ICaseModel) => {
        const alertList = this.state.alertList;
        for (let i = 0; i < alertList.length; i++) {
            if (alertList[i].originEntityKey == updatedCase.guid) {
                alertList[i].originEntityName = updatedCase.caseName;
                alertList[i].sqScore = updatedCase.qScore;
            }
        }

        this.setState({
            easyUpdateMode: false,
            easyUpdateCaseGuid: undefined,
            alertList: alertList,
        });
    };

    easyUpdateCancel = () => {
        this.setState({
            easyUpdateMode: false,
            easyUpdateCaseGuid: undefined,
        });
    };

    getSelectedFilters = () => {
        let selectedFilters = 'TYPE:' + '\n';
        for (let i = 0; i < this.state.selectedAlertOriginTypesOptions.length; i++) {
            selectedFilters += this.state.selectedAlertOriginTypesOptions[i].label + '\n';
        }
        selectedFilters += '\n';

        if (
            this.props.alertListType === AlertListTypeEnum.MyAlertList &&
            this.state.selectedMyAlertListStatusOptions.length > 0
        ) {
            selectedFilters += 'STATUS:' + '\n';
            for (let i = 0; i < this.state.selectedMyAlertListStatusOptions.length; i++) {
                selectedFilters += this.state.selectedMyAlertListStatusOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        } else if (
            this.props.alertListType === AlertListTypeEnum.ZoneAlertList &&
            this.state.selectedAlertStatusOptions.length > 0
        ) {
            selectedFilters += 'STATUS:' + '\n';
            for (let i = 0; i < this.state.selectedAlertStatusOptions.length; i++) {
                selectedFilters += this.state.selectedAlertStatusOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedTypeOptions.length > 0) {
            selectedFilters += 'Alert Type:' + '\n';
            for (let i = 0; i < this.state.selectedTypeOptions.length; i++) {
                selectedFilters += this.state.selectedTypeOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedSubTypeOptions.length > 0) {
            selectedFilters += 'Sub Type:' + '\n';
            for (let i = 0; i < this.state.selectedSubTypeOptions.length; i++) {
                selectedFilters += this.state.selectedSubTypeOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedUserOptions.length > 0) {
            selectedFilters += 'USERS:' + '\n';
            for (let i = 0; i < this.state.selectedUserOptions.length; i++) {
                selectedFilters += this.state.selectedUserOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedNoteCategoryOptions.length > 0) {
            selectedFilters += 'NOTE CATEGORIES:' + '\n';
            for (let i = 0; i < this.state.selectedNoteCategoryOptions.length; i++) {
                selectedFilters += this.state.selectedNoteCategoryOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedNoteSubCategoryOptions.length > 0) {
            selectedFilters += 'NOTE SUB-CATEGORIES:' + '\n';
            for (let i = 0; i < this.state.selectedNoteSubCategoryOptions.length; i++) {
                selectedFilters += this.state.selectedNoteSubCategoryOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedPurposeOptions.length > 0) {
            selectedFilters += 'PURPOSE:' + '\n';
            for (let i = 0; i < this.state.selectedPurposeOptions.length; i++) {
                selectedFilters += this.state.selectedPurposeOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        return selectedFilters;
    };

    getEnabledFilters = ({
        isMyAlertList,
        isZoneAlertList,
    }: {
        isMyAlertList: boolean;
        isZoneAlertList: boolean;
    }) => {
        let enabledFilters: string[] = [];
        const { selectedAlertOriginTypesOptions } = this.state;

        const isCaseNotesSelected = selectedAlertOriginTypesOptions.some(
            (opt) => opt.id === AlertOriginTypesEnum.CaseNote
        );

        const isOnlyCaseNoteSelected =
            selectedAlertOriginTypesOptions.length === 1 && isCaseNotesSelected;

        //default filters for Act and watch.
        enabledFilters = ['type'];
        if (isMyAlertList) {
            if (isOnlyCaseNoteSelected) {
                enabledFilters = [
                    'myAlertListStatus',
                    'type',
                    'noteCategory',
                    'noteSubCategory',
                    'purpose',
                ];
            } else {
                enabledFilters = ['type', 'myAlertListStatus'];
            }
        } else if (isZoneAlertList) {
            if (isOnlyCaseNoteSelected) {
                enabledFilters = [
                    'noteStatus',
                    'type',
                    'noteCategory',
                    'noteSubCategory',
                    'purpose',
                    'user',
                ];
            } else {
                enabledFilters = ['type', 'user', 'noteStatus'];
            }
        }

        const onlyTypeSelectedIsReport =
            this.state.selectedAlertOriginTypesOptions.length === 1 &&
            this.state.selectedAlertOriginTypesOptions[0].id === AlertOriginTypesEnum.Report;

        const alertTypeIsAct = this.state.selectedTypeOptions.some(
            (opt) => opt.id === AlertTypes.Act.Value
        );

        if (onlyTypeSelectedIsReport && alertTypeIsAct) {
            enabledFilters.push('subtype');
        }

        return enabledFilters;
    };

    getViewEditActionUI = (item: IAlertListModel) => {
        switch (item.originTypeId) {
            case AlertOriginTypesEnum.CaseNote:
                return (
                    <>
                        <button
                            className="btn btn-no-bg"
                            onClick={() => {
                                this.setState({
                                    currentNote: {
                                        guid: item.originEntitySubKey,
                                        purpose: { id: 0 },
                                        status: { id: 0 },
                                        type: { id: this.getNoteType(item) },
                                    },
                                    openNoteEditor: true,
                                    forceNoteEditorInReadOnlyMode: true,
                                });
                            }}
                        >
                            <i className="fal fa-lg fa-eye" />
                        </button>
                        {item.allowEdit ? (
                            <button
                                className="btn btn-no-bg"
                                onClick={() => {
                                    this.setState({
                                        currentNote: {
                                            guid: item.originEntitySubKey,
                                            purpose: { id: 0 },
                                            status: { id: 0 },
                                            type: { id: this.getNoteType(item) },
                                        },
                                        openNoteEditor: true,
                                        forceNoteEditorInReadOnlyMode: false,
                                    });
                                }}
                            >
                                <i className="fal fa-lg fa-pen" />
                            </button>
                        ) : null}
                    </>
                );
            case AlertOriginTypesEnum.CaseTrigger:
                return (
                    <>
                        <button
                            className="btn btn-no-bg"
                            onClick={() => this.markCaseTriggerAsRead(item.guid, true)}
                        >
                            <i className="fal fa-lg fa-eye" />
                        </button>
                        {!item.alertReadForLoggedInUser ? (
                            <button
                                className="btn btn-no-bg"
                                onClick={() => this.markCaseTriggerAsRead(item.guid)}
                            >
                                <i className="fal fa-lg fa-check" />
                            </button>
                        ) : null}
                    </>
                );
            default:
                break;
        }
    };

    buildCaseTriggerAlertDetails = () => {
        const match = this.state.alertList.find(
            (x) => x.guid === this.state.selectedCaseTriggerAlertGuid
        );
        if (!match || !match.caseTriggerDetail) return null;

        return (
            <>
                {match.caseTriggerDetail.fields ? (
                    <>
                        <h5>Case Data</h5>
                        <table className="table full-width mt-2">
                            <thead>
                                <tr>
                                    <th className="col-sm-3">Name</th>
                                    <th className="col-sm-3">Value</th>
                                    <th className="col-sm-3">Previous Value</th>
                                    <th className="col-sm-3">Rule Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {match.caseTriggerDetail.fields.map(
                                    (item: ICaseTriggerFieldModel) => {
                                        const cssClass = classNames({
                                            'font-orange': item.value !== item.previousValue,
                                        });

                                        return (
                                            <tr>
                                                <td>{item.name ?? ''}</td>
                                                <td className={cssClass}>{item.value}</td>
                                                <td className={cssClass}>{item.previousValue}</td>
                                                <td>{item.ruleName}</td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </>
                ) : null}
                {match.caseTriggerDetail.triggerRules ? (
                    <>
                        <h5 className="mt-4">Trigger Rule Details</h5>

                        <table className="table full-width mt-2">
                            <thead>
                                <tr>
                                    <th className="col-sm-4">Rule Name</th>
                                    <th className="col-sm-4">Case Criteria</th>
                                    <th className="col-sm-4">Trigger Field(s)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {match.caseTriggerDetail.triggerRules.map(
                                    (item: ITriggerRuleSettingsModel) => {
                                        return (
                                            <tr key={item.guid}>
                                                <td>{item.ruleName ?? ''}</td>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            {item.filters
                                                                .map(caseManagerMapper)
                                                                .map((item: any, idx: number) => {
                                                                    let fieldSummary =
                                                                        item.label + ': ';

                                                                    if (
                                                                        item.isAssertionDescriptor
                                                                    ) {
                                                                        const assertionDescriptorMatch =
                                                                            this.props.assertionDescriptors.find(
                                                                                (x: any) =>
                                                                                    item.assertionDescriptorGuid !==
                                                                                        undefined &&
                                                                                    x.guid !==
                                                                                        undefined &&
                                                                                    x.guid.toUpperCase() ===
                                                                                        item.assertionDescriptorGuid.toUpperCase()
                                                                            );

                                                                        if (
                                                                            assertionDescriptorMatch
                                                                        ) {
                                                                            const summary =
                                                                                TriggerRuleHelper.getAssertionDescriptorSummary(
                                                                                    item,
                                                                                    assertionDescriptorMatch
                                                                                );
                                                                            fieldSummary += summary;
                                                                        }
                                                                    } else {
                                                                        fieldSummary +=
                                                                            TriggerRuleHelper.getNonAssertionDescriptorSummary(
                                                                                item
                                                                            );
                                                                    }
                                                                    return (
                                                                        <tr
                                                                            key={
                                                                                '_criteriaSum_' +
                                                                                idx
                                                                            }
                                                                        >
                                                                            <td>{fieldSummary}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            {item.triggerRules.map(
                                                                (item: any, idx: number) => {
                                                                    let fieldSummary =
                                                                        item.label + ': ';

                                                                    if (
                                                                        item.isAssertionDescriptor
                                                                    ) {
                                                                        const assertionDescriptorMatch =
                                                                            this.props.assertionDescriptors.find(
                                                                                (x: any) =>
                                                                                    item.assertionDescriptorGuid !==
                                                                                        undefined &&
                                                                                    x.guid !==
                                                                                        undefined &&
                                                                                    x.guid.toUpperCase() ===
                                                                                        item.assertionDescriptorGuid.toUpperCase()
                                                                            );

                                                                        if (
                                                                            assertionDescriptorMatch
                                                                        )
                                                                            fieldSummary =
                                                                                TriggerRuleHelper.getTriggerAssertionDescriptorSummary(
                                                                                    item,
                                                                                    assertionDescriptorMatch
                                                                                );
                                                                    } else {
                                                                        fieldSummary =
                                                                            TriggerRuleHelper.getTriggerNonAssertionDescriptorSummary(
                                                                                item
                                                                            );
                                                                    }
                                                                    return (
                                                                        <tr
                                                                            key={
                                                                                '_trigSummary_' +
                                                                                idx
                                                                            }
                                                                        >
                                                                            <td>{fieldSummary}</td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </>
                ) : null}
            </>
        );
    };

    handleFavoriteValidateFilters = () => {
        const valid = this.state.selectedAlertOriginTypesOptions.length > 0;

        if (!valid) {
            this.setState({
                validation: {
                    favorites: ['Enter at least one type to save a favorite'],
                    model: this.state.validation.model,
                },
                pendingResponse: false,
            });
            return true;
        }

        this.setState({
            validation: {
                favorites: [],
            },
            pendingResponse: false,
        });
        return false;
    };

    getEntityURL(entityTypeId: number, originSubEntityType?: number): string {
        switch (entityTypeId) {
            case EntityTypes.PackageSettlement:
                return LocalRoutes.PackageSettlementDetails;
            case EntityTypes.Case:
                return LocalRoutes.CaseFile;
            case EntityTypes.Report:
                switch (originSubEntityType) {
                    case ReportTypesEnum.WeeklyRecap.Value:
                        return LocalRoutes.WeeklyRecap;
                    default:
                        return '';
                }
            default:
                return '';
        }
    }

    getNoteType(item: IAlertListModel): number {
        let noteTypeId = NoteTypes.CaseNote;
        switch (item.originTypeId) {
            case AlertOriginTypesEnum.StrategyNote:
                noteTypeId = NoteTypes.StrategyNote;
                break;
            case AlertOriginTypesEnum.ZoneNote:
                noteTypeId = NoteTypes.ZoneNote;
                break;
            case AlertOriginTypesEnum.PackageSettlementNote:
                noteTypeId = NoteTypes.PackageSettlementNote;
                break;
            default:
                noteTypeId = NoteTypes.CaseNote;
                break;
        }

        return noteTypeId;
    }

    render() {
        const isMyAlertList = this.props.alertListType === AlertListTypeEnum.MyAlertList;
        const isZoneAlertList = this.props.alertListType === AlertListTypeEnum.ZoneAlertList;

        const enabledFilters = this.getEnabledFilters({ isMyAlertList, isZoneAlertList });

        const isCaseTriggerSelected = this.state.selectedAlertOriginTypesOptions.some(
            (opt) => opt.id === AlertOriginTypesEnum.CaseTrigger
        );

        if (this.state.openNoteEditor)
            return (
                <NoteEditor
                    forceNoteEditorInReadOnlyMode={this.state.forceNoteEditorInReadOnlyMode}
                    authorizedToEdit={!this.state.forceNoteEditorInReadOnlyMode}
                    caseLocalCounselGuid={this.getCaseLocalCounselOrgGuid()}
                    defaultAlertTarget={
                        Authorization.isLocalScope(this.props.user)
                            ? this.state.currentNote.caseManager
                            : undefined
                    }
                    defaultWatchTargets={
                        Authorization.isLocalScope(this.props.user) &&
                        this.state.currentNote.alternateCaseManager
                            ? [this.state.currentNote.alternateCaseManager]
                            : undefined
                    }
                    caseTitle={this.getCaseTitle()}
                    user={this.props.user}
                    noteCategoryTypes={this.props.noteCategoryTypes.filter(
                        (x) => x.noteType && x.noteType.id === NoteTypes.CaseNote
                    )}
                    currentNote={this.state.currentNote}
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.handleNoteEditorCancel}
                />
            );

        const debugLayout = false;

        const isEasyUpdate = () => {
            return (
                debugLayout ||
                (this.state.enableEasyUpdate &&
                    Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, this.props.user))
            );
        };

        const allowEasyUpdate = (originTypeId: number) => {
            return (
                originTypeId === AlertOriginTypesEnum.CaseNote ||
                originTypeId === AlertOriginTypesEnum.CaseTrigger
            );
        };

        const buttonLayoutWidth = () => {
            return isEasyUpdate() ? 140 : 112;
        };

        const getStrategyURL = (strategyTypeId: number): string => {
            switch (strategyTypeId) {
                case StrategyTypes.Jurisdiction.Value:
                    return LocalRoutes.JurisdictionStrategyDetail;
                case StrategyTypes.PlaintiffsFirm.Value:
                    return LocalRoutes.PlaintiffFirmStrategyDetail;
                case StrategyTypes.LocalCounsel.Value:
                    return LocalRoutes.LocalCounselStrategyDetail;
                case StrategyTypes.Projects.Value:
                    return LocalRoutes.ProjectDetail;
                case StrategyTypes.Experts.Value:
                    return LocalRoutes.ExpertsDetails;
                default:
                    return '';
            }
        };

        const getNavigationUrl = (item: IAlertListModel): string => {
            let navigationUrl: string = item.originEntityKey;

            if (item.originTypeId!) {
                if (item.originStrategyType === null || item.originStrategyType === undefined) {
                    navigationUrl = item.originEntityType
                        ? this.getEntityURL(
                              item.originEntityType ?? 0,
                              item.originSubEntityType
                          ).replace(':guid', item.originEntityKey ?? '')
                        : LocalRoutes.CaseFile.replace(':guid', item.originEntityKey ?? '');
                } else if (item.originStrategyType > 0) {
                    navigationUrl = getStrategyURL(item.originStrategyType ?? 0).replace(
                        ':guid',
                        item.originEntityKey ?? ''
                    );
                }
            }

            return navigationUrl;
        };

        return (
            <div>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, this.props.user) && (
                    <CaseEasyUpdate
                        user={this.props.user}
                        caseGuid={this.state.easyUpdateCaseGuid}
                        onComplete={this.easyUpdateDone}
                        onCancel={this.easyUpdateCancel}
                        hidden={!this.state.easyUpdateMode}
                        excludeNotes={true}
                        additionalHeaderText={
                            this.props.alertListType === AlertListTypeEnum.MyAlertList
                                ? 'Update Case Data | My Alert List'
                                : this.props.alertListType === AlertListTypeEnum.ZoneAlertList
                                ? 'Update Case Data | Zone Alert List'
                                : ''
                        }
                    />
                )}

                {this.state.easyUpdateMode ? null : this.state.pendingResponse ? (
                    <Loader />
                ) : (
                    <>
                        <div className="row mb-3">
                            <div className="col-sm-3">
                                <h1 className="d-inline-block">
                                    {this.props.alertListType === AlertListTypeEnum.MyAlertList
                                        ? 'My Alert List'
                                        : 'Zone Alert List'}
                                </h1>
                                {this.props.alertListType === AlertListTypeEnum.MyAlertList ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="My Alert List"
                                        helpText="Alerts in this view are limited to those alerts that include YOU as the alert target or as part of the watch group.  MY ALERT LIST displays unread (bold font) and read status and you can filter on unread/read status."
                                    />
                                ) : (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Zone Alert List"
                                        helpText="This view includes ALL alerts for the zone.  This view does not display unread/read status and you cannot filter on that status; however,  if you read an alert from this view and then see that same alert in MY ALERT LIST, the alert will display as read."
                                    />
                                )}
                            </div>
                            <div className="col-sm-9">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() => {
                                        this.loadAlertList(ListMode.UserDefault);
                                    }}
                                >
                                    Refresh
                                </button>
                                {this.props.alertListType && (
                                    <Favorite
                                        user={this.props.user}
                                        type={{ id: this.props.alertListType }}
                                        currentFavoriteSettingJson={JSON.stringify(
                                            this.state.alertSearchParameters
                                        )}
                                        currentFavoriteDetailsString={
                                            this.state.currentFavoriteDetailsString
                                        }
                                        handleFavoriteSelected={this.handleFavoriteSelected}
                                        applyFavoriteSelected={this.applyFavoriteSelected}
                                        handleDefaultFavoriteChange={
                                            this.handleDefaultFavoriteChange
                                        }
                                        onValidateFilters={this.handleFavoriteValidateFilters}
                                    />
                                )}
                                {Authorization.isAuthorizedToRoute(
                                    LocalRoutes.CaseEasyUpdate,
                                    this.props.user
                                ) ? (
                                    <span className="btn btn-no-bg float-end text-gray ps-3">
                                        <input
                                            name="chkEasyUpdate"
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.setState({
                                                    enableEasyUpdate: e.target.checked,
                                                });
                                            }}
                                            checked={this.state.enableEasyUpdate}
                                        />
                                        <i className="fal fa-edit ps-2 pe-1" />
                                        Easy Update
                                    </span>
                                ) : null}
                                <Link
                                    className="float-end"
                                    target="_blank"
                                    to={LocalRoutes.ViewSpecificPDF.replace(
                                        ':id',
                                        TutorialTopics.Alerts.toString()
                                    )}
                                >
                                    <span className="btn btn-no-bg text-gray">
                                        <i className={'fal fa-graduation-cap'} />
                                        &nbsp;Tutorials
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className="row text-danger mb-2">
                            {this.state.validation.model
                                ? this.state.validation.model.map((s: string) => {
                                      return <span>{s}</span>;
                                  })
                                : null}
                            {this.state.validation.favorites
                                ? this.state.validation.favorites.map((s: string) => {
                                      return <span>{s}</span>;
                                  })
                                : null}
                        </div>
                        <div>
                            <div className="mt-2">
                                <div className="row no-padding">
                                    <div className="col flex-grow-0 case-profile-item-div">
                                        <div className="text-gray text-lg" style={{ width: 50 }}>
                                            Type
                                        </div>
                                    </div>

                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 230 }}
                                    >
                                        <CheckmateNSelect
                                            name="alertOriginType"
                                            options={this.state.alertOriginTypes}
                                            value={this.state.selectedAlertOriginTypesOptions}
                                            onChange={this.handleAlertOriginTypeFilterOptionsChange}
                                            placeholder="-- Type --"
                                        />
                                    </div>
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 230 }}
                                    />
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 230 }}
                                    />
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 230 }}
                                    />
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 230 }}
                                    />
                                    {isZoneAlertList && (
                                        <div
                                            className="col horizontal-padding"
                                            style={{ maxWidth: 230 }}
                                        />
                                    )}
                                    <div className="col flex-grow-0">
                                        <div style={{ width: 110 }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-2">
                                <div className="row no-padding">
                                    <div className="col flex-grow-0 case-profile-item-div">
                                        <div className="text-gray text-lg" style={{ width: 50 }}>
                                            Filter
                                        </div>
                                    </div>
                                    {isMyAlertList ? (
                                        <div
                                            className="col horizontal-padding"
                                            style={{ maxWidth: 230 }}
                                        >
                                            <CheckmateNSelect
                                                name="myAlertListStatus"
                                                options={MyAlertListSearchStatusOptions}
                                                value={this.state.selectedMyAlertListStatusOptions}
                                                onChange={
                                                    this.handleMyAlertListStatusFilterOptionsChange
                                                }
                                                placeholder="-- Status --"
                                                disabled={
                                                    !enabledFilters.includes('myAlertListStatus')
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className="col horizontal-padding"
                                            style={{ maxWidth: 230 }}
                                        >
                                            <CheckmateNSelect
                                                name="noteStatus"
                                                options={this.state.alertStatusTypes}
                                                value={this.state.selectedAlertStatusOptions}
                                                onChange={this.onAlertStatusFilterOptionsChange}
                                                placeholder="-- Status --"
                                                disabled={!enabledFilters.includes('noteStatus')}
                                            />
                                        </div>
                                    )}
                                    {isZoneAlertList && (
                                        <div
                                            className="col horizontal-padding"
                                            style={{ maxWidth: 230 }}
                                        >
                                            <CheckmateNSelect
                                                name="user"
                                                options={this.state.users}
                                                value={this.state.selectedUserOptions}
                                                onChange={this.onUserFilterOptionsChange}
                                                placeholder="-- Alert User --"
                                                disabled={!enabledFilters.includes('user')}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 230 }}
                                    >
                                        <CheckmateNSelect
                                            name="type"
                                            options={this.state.types}
                                            value={this.state.selectedTypeOptions}
                                            onChange={this.onTypeFilterOptionsChange}
                                            placeholder="-- Act/Watch --"
                                            disabled={
                                                !enabledFilters.includes('type') ||
                                                isCaseTriggerSelected
                                            }
                                        />
                                    </div>
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 230 }}
                                    >
                                        <CheckmateNSelect
                                            name="subType"
                                            options={this.state.subTypes}
                                            value={this.state.selectedSubTypeOptions}
                                            onChange={this.onSubTypeFilterOptionsChange}
                                            placeholder="-- Act Type --"
                                            disabled={
                                                !enabledFilters.includes('subtype') ||
                                                isCaseTriggerSelected
                                            }
                                        />
                                    </div>
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 230 }}
                                    >
                                        <CheckmateNSelect
                                            name="noteCategory"
                                            options={this.getNoteCategoryTypeOptions()}
                                            value={this.state.selectedNoteCategoryOptions}
                                            onChange={this.onNoteCategoryFilterOptionsChange}
                                            placeholder="-- Category --"
                                            disabled={!enabledFilters.includes('noteCategory')}
                                        />
                                    </div>
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 230 }}
                                    >
                                        <CheckmateNSelect
                                            name="noteSubCategory"
                                            options={this.getNoteSubCategoryTypeOptions()}
                                            value={this.state.selectedNoteSubCategoryOptions}
                                            onChange={this.onNoteSubCategoryFilterOptionsChange}
                                            placeholder="-- Sub Category --"
                                            disabled={!enabledFilters.includes('noteSubCategory')}
                                        />
                                    </div>
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 230 }}
                                    >
                                        <CheckmateNSelect
                                            name="purpose"
                                            options={this.state.purposes}
                                            value={this.state.selectedPurposeOptions}
                                            onChange={this.onPurposeFilterOptionsChange}
                                            placeholder="-- Purpose --"
                                            disabled={!enabledFilters.includes('purpose')}
                                        />
                                    </div>
                                    <div
                                        className="col flex-grow-0"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Help
                                            type={ApplicationHelpType.Check}
                                            title="Selected Filters"
                                            helpText={this.getSelectedFilters()}
                                            additionalStyleClassNames="float-end"
                                        />
                                        <button
                                            className="btn btn-black btn-icon float-end"
                                            onClick={() => {
                                                this.loadAlertList(ListMode.RunSearch);
                                            }}
                                        >
                                            <i className="fal fa-filter color-white" />
                                        </button>
                                        <button
                                            className="btn btn-gray btn-icon float-end"
                                            onClick={() => {
                                                this.loadAlertList(ListMode.SystemDefault);
                                            }}
                                        >
                                            <i className="fal fa-times color-white" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr style={{ verticalAlign: 'middle' }}>
                                    <SortableHeader
                                        headerText="Type"
                                        sortKey="originType"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Name"
                                        sortKey="originEntityName"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                    />
                                    <SortableHeader
                                        headerComponent={
                                            <div style={{ whiteSpace: 'nowrap' }}>SQ Score</div>
                                        }
                                        sortKey="sqScore"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Act Type"
                                        sortKey="subType"
                                        onSort={this.sortData}
                                        noWrap
                                    />
                                    <th className="col-sm-1">Category/Details</th>
                                    <SortableHeader
                                        headerText="Decision"
                                        sortKey="decision"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Action Target"
                                        sortKey="actionTargetName"
                                        onSort={this.sortData}
                                        noWrap
                                    />
                                    <SortableHeader
                                        headerText="Writer"
                                        sortKey="writer"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Date"
                                        sortKey="modifiedDate"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Purpose"
                                        sortKey="purpose"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Status"
                                        sortKey="status"
                                        onSort={this.sortData}
                                    />
                                    <th /* width={60} */></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.alertList.map((item: IAlertListModel, i: number) => {
                                    const nameNavigateUrl = getNavigationUrl(item);

                                    const actTypeComp =
                                        item.subTypeId === AlertSubTypesEnum.Chat ? (
                                            <i className="fal fa-messages" title="Chat" />
                                        ) : item.subTypeId === AlertSubTypesEnum.Collab ? (
                                            <i className="fal fa-users" title="Collab" />
                                        ) : null;

                                    return (
                                        <tr
                                            key={i}
                                            className={
                                                this.props.alertListType ===
                                                    AlertListTypeEnum.MyAlertList &&
                                                !item.alertReadForLoggedInUser
                                                    ? 'bold'
                                                    : ''
                                            }
                                        >
                                            <td>{item.originType}</td>
                                            <td>
                                                {nameNavigateUrl ? (
                                                    <Link to={nameNavigateUrl} target="_blank">
                                                        {item.originEntityName}
                                                    </Link>
                                                ) : (
                                                    item.originEntityName
                                                )}
                                            </td>
                                            <td width={80}>{item!.sqScore}</td>
                                            <td>{actTypeComp}</td>
                                            <td>
                                                <ul>
                                                    {item.details
                                                        ?.split('\n')
                                                        .map((value: string, i: number) => {
                                                            return <li key={i}>{value}</li>;
                                                        })}
                                                </ul>
                                            </td>
                                            <td>{item.decision}</td>
                                            <td>{item.actionTargetName}</td>
                                            <td>{item.writer}</td>
                                            <td>
                                                {Common.dateTimeFormatToLocal(item.modifiedDate)}
                                            </td>
                                            <td>{item.purpose}</td>
                                            <td>{item.status}</td>
                                            <td
                                                class-name="text-nowrap"
                                                width={buttonLayoutWidth()}
                                            >
                                                {allowEasyUpdate(item.originTypeId) &&
                                                    isEasyUpdate() && (
                                                        <button
                                                            className="btn-no-bg float-end padding-left-10 padding-top-5"
                                                            onClick={(
                                                                e: React.MouseEvent<HTMLButtonElement>
                                                            ) => {
                                                                this.handleEasyUpdate(e, item);
                                                            }}
                                                        >
                                                            <i
                                                                className={`fal fa-lg fa-edit ${
                                                                    this.easyUpdateAttempted(
                                                                        item.originEntityKey!
                                                                    )
                                                                        ? 'color-gray'
                                                                        : 'color-black'
                                                                } `}
                                                            />
                                                        </button>
                                                    )}
                                                {this.getViewEditActionUI(item)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                )}

                {this.state.showTooManyRecordsToProcessDialog && (
                    <Modal
                        centered
                        show={this.state.showTooManyRecordsToProcessDialog}
                        onHide={() => this.setState({ showTooManyRecordsToProcessDialog: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Alert List Results </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid">
                                <span>
                                    Your search filter returned more than{' '}
                                    {this.state.maxZoneAlertListCount.toLocaleString('en-US')}{' '}
                                    records, which is over our display limit. Please refine your
                                    search and try again or if you did not run this by error, please
                                    send an email with the following information to{' '}
                                    <a href={'mailto:ltm@checkmate.legal'}>ltm@checkmate.legal</a>{' '}
                                    and we will perform this query for you.{' '}
                                </span>
                                <br />
                                <ul>
                                    <li>- Purpose for the query</li>
                                    <li>- When you need the results</li>
                                    <li>- Filter Options</li>
                                </ul>
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({ showTooManyRecordsToProcessDialog: false })
                                    }
                                >
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}

                {this.state.openCaseTriggerDetailPopup &&
                    this.state.selectedCaseTriggerAlertGuid && (
                        <Modal
                            centered
                            show={true}
                            onHide={() =>
                                this.setState({
                                    openCaseTriggerDetailPopup: false,
                                    selectedCaseTriggerAlertGuid: undefined,
                                })
                            }
                            backdrop={false}
                            size="lg"
                        >
                            <Modal.Header>
                                <Modal.Title> Case Trigger Details </Modal.Title>
                                <button
                                    className="btn btn-no-bg float-end"
                                    onClick={() =>
                                        this.setState({
                                            openCaseTriggerDetailPopup: false,
                                            selectedCaseTriggerAlertGuid: undefined,
                                        })
                                    }
                                >
                                    <i className="fal fa-xmark-large" />
                                </button>
                            </Modal.Header>
                            <Modal.Body>{this.buildCaseTriggerAlertDetails()}</Modal.Body>
                        </Modal>
                    )}
            </div>
        );
    }
}

const caseManagerMapper = (item: IQueryFilterCriteria) => {
    if (item.staticItemId === CaseFileStaticItems.CaseManager.Id) {
        return { ...item, label: 'A1/A2' };
    }
    return item;
};
