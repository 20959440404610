import {
    ApplicationHelpType,
    DocumentTypeEnum,
    DocumentTypeOptions,
    ReportStatusOptionsList,
    ReportTypesEnum,
} from '../../utilities/Constants';

import CheckmateNSelect from '../../components/shared/CheckmateNSelect';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import { Help } from '../../components/shared/Help';
import { IDocumentSearchModel } from '../../interfaces/IDocument';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import React from 'react';
import { Stack } from 'react-bootstrap';

interface IDocumentFilterProps {
    documentSearchModel: IDocumentSearchModel;
    filteredReportTypeOptions: IMultiSelectOptions[];
    getSelectedFilters: () => string;
    onDocumentTypeChange: (options: IMultiSelectOptions[]) => void;
    onReportTypeChange: (options: IMultiSelectOptions[]) => void;
    onStatusChange: (options: IMultiSelectOptions[]) => void;
    onDateChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        searchModel: IDocumentSearchModel
    ) => void;
    onClearFilter: (e: React.FormEvent<HTMLButtonElement>) => void;
    onReadChange: (optionalBool: IMultiSelectOptions | undefined) => void;
    onRunSearch: (e: React.FormEvent<HTMLButtonElement> | null) => void;
    selectedDocumentTypeOptions: IMultiSelectOptions[];
    selectedReportTypeOptions: IMultiSelectOptions[];
    selectedStatusOptions: IMultiSelectOptions[];
    selectedReadOption: IMultiSelectOptions | undefined;
}

const DocumentFilters: React.FC<IDocumentFilterProps> = ({
    documentSearchModel,
    filteredReportTypeOptions,
    getSelectedFilters,
    onClearFilter,
    onDateChange,
    onDocumentTypeChange,
    onReadChange,
    onReportTypeChange,
    onRunSearch,
    onStatusChange,
    selectedDocumentTypeOptions,
    selectedReadOption,
    selectedReportTypeOptions,
    selectedStatusOptions,
}) => {
    //currently hiding the OfficialCaseDocument && WorkProduct
    const staticDocumentTypeFilter = (staticItem: IMultiSelectOptions) =>
        staticItem.id !== DocumentTypeEnum.OfficialCaseDocument &&
        staticItem.id !== DocumentTypeEnum.WorkProduct;

    const filterDocumentTypeOptions = DocumentTypeOptions.filter(staticDocumentTypeFilter);

    const typesWithSubTypes = [DocumentTypeEnum.Report];
    const onlyOneTypeIsSelected = (selectedDocumentTypeOptions?.length ?? 0) === 1;

    const enableSubType =
        onlyOneTypeIsSelected &&
        selectedDocumentTypeOptions.some((opt) => typesWithSubTypes.includes(opt.id));

    return (
        <div className="mt-3">
            <Stack direction="horizontal" gap={3}>
                <div className="col flex-grow-0 case-profile-item-div">
                    <span className="text-gray text-lg">Filter</span>
                </div>
                <div style={{ width: 250 }}>
                    <CheckmateNSelect
                        options={filterDocumentTypeOptions}
                        value={selectedDocumentTypeOptions}
                        onChange={onDocumentTypeChange}
                        placeholder="-- Type --"
                    />
                </div>
                <div style={{ width: 250 }}>
                    <CheckmateNSelect
                        options={filteredReportTypeOptions}
                        value={selectedReportTypeOptions}
                        onChange={onReportTypeChange}
                        placeholder="-- Sub Type --"
                        disabled={!enableSubType}
                    />
                </div>
                <div style={{ width: 250 }}>
                    <CheckmateNSelect
                        options={ReportStatusOptionsList.map((lu) => ({
                            id: lu.id,
                            value: lu.id.toString(),
                            label: lu.displayName ?? '',
                        }))}
                        value={selectedStatusOptions}
                        onChange={onStatusChange}
                        placeholder="-- Status --"
                    />
                </div>
                <div style={{ width: 250 }}>
                    <CheckmateSelect
                        options={CheckmateSelectHelper.getReadUnreadOptions()}
                        value={selectedReadOption}
                        placeholder="-- Read / Unread --"
                        onChange={onReadChange}
                    />
                </div>
                <Stack direction="horizontal" gap={2}>
                    <input
                        className={
                            'form-control' +
                            (documentSearchModel.startDate &&
                            Common.isValidDate(documentSearchModel.startDate)
                                ? ''
                                : ' unselectClass')
                        }
                        min="1753-01-01"
                        disabled={false}
                        value={
                            documentSearchModel.startDate
                                ? Common.dateFormat(documentSearchModel.startDate)
                                : ''
                        }
                        type="date"
                        name="startDate"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onDateChange(e, documentSearchModel)
                        }
                    />
                    <span>to</span>
                    <input
                        className={
                            'form-control d-inline-block' +
                            (documentSearchModel.endDate &&
                            Common.isValidDate(documentSearchModel.endDate)
                                ? ''
                                : ' unselectClass')
                        }
                        value={
                            documentSearchModel.endDate
                                ? Common.dateFormat(documentSearchModel.endDate)
                                : ''
                        }
                        min="1753-01-01"
                        disabled={false}
                        type="date"
                        name="endDate"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onDateChange(e, documentSearchModel)
                        }
                    />
                </Stack>

                <div
                    className="col flex-grow-0"
                    style={{
                        whiteSpace: 'nowrap',
                        display: 'inline-block',
                    }}
                >
                    <Help
                        type={ApplicationHelpType.Check}
                        title="Selected Filters"
                        helpText={getSelectedFilters()}
                    />
                    <button
                        className="btn btn-black btn-icon"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => onRunSearch(e)}
                    >
                        <i className="fal fa-filter color-white" />
                    </button>
                    <button className="btn btn-gray btn-icon" onClick={onClearFilter}>
                        <i className="fal fa-times color-white" />
                    </button>
                </div>
            </Stack>
        </div>
    );
};

export default DocumentFilters;
