import { ApiRoutes } from '../../../../utilities/ApiRoutes';
import { ExportFileButton } from '../../../../components/shared/ExportFileButton';
import { ITimelineReportInputModel } from '../../../../interfaces/Report/ITimelineReport';
import { ReportTypesEnum } from '../../../../utilities/Constants';
import { Stack } from 'react-bootstrap';
import classNames from 'classnames';

interface IWeeklyRecapReadOnlyActionsProps {
    forceReadonly?: boolean;
    hasChatActs?: boolean;
    hideCollabButton?: boolean;
    hideCloseReviewAct?: boolean;
    inputModel: ITimelineReportInputModel;
    isPublished?: boolean;
    onCloseReviewActClick: () => void;
    onCollabClick: () => void;
    onChatClick: () => void;
    onEditClick: () => void;
    onExportError?: () => void;
    onHistoryClick: () => void;
    onPreviewClick: () => void;
    previewText: string;
}

export function WeeklyRecapReadOnlyActions(props: IWeeklyRecapReadOnlyActionsProps) {
    const { reportSectionDetails, startDate, endDate, ...options } = props.inputModel;
    
    return (
        <Stack direction="horizontal" style={{ flexWrap: 'wrap' }}>
            {!props.isPublished && (
                <button className="btn btn-no-bg text-gray" onClick={props.onPreviewClick}>
                    <i className="fal fa-magnifying-glass" />
                    &nbsp;{props.previewText}
                </button>
            )}
            {!props.hideCloseReviewAct && (
                <button className="btn btn-no-bg font-orange" onClick={props.onCloseReviewActClick}>
                    <i className="fal fa-check" />
                    &nbsp;Close Review Act
                </button>
            )}
            {!props.hideCollabButton && (
                <button className="btn btn-no-bg text-gray" onClick={props.onCollabClick}>
                    <i className="fal fa-users" />
                    &nbsp;Collab
                </button>
            )}
            {!props.isPublished && (
                <button className="btn btn-no-bg text-gray" onClick={props.onHistoryClick}>
                    <i className="fal fa-history" />
                    &nbsp;History
                </button>
            )}
            <ExportFileButton
                url={'/' + ApiRoutes.TimelineRecapExport}
                reportType={{
                    id: ReportTypesEnum.WeeklyRecap.Value,
                }}
                reportParameters={{
                    reportType: {
                        id: ReportTypesEnum.WeeklyRecap.Value,
                    },
                    options,
                    reportSectionDetails,
                    date: {
                        startDate,
                        endDate,
                    },
                }}
                onError={props.onExportError}
                icon="fa-file-pdf"
            />
            <button
                className={classNames('btn btn-no-bg', {
                    'font-orange': props.hasChatActs,
                    'text-gray': !props.hasChatActs,
                })}
                onClick={props.onChatClick}
            >
                <i className="fal fa-messages" />
                &nbsp;Chat
            </button>
            {!props.isPublished && !props.forceReadonly && (
                <button className="btn btn-no-bg text-gray" onClick={props.onEditClick}>
                    <i className="fal fa-pen" />
                    &nbsp;Edit
                </button>
            )}
        </Stack>
    );
}
