import {
    AssertionDescriptorUsageTypesEnum,
    AssertionDescriptorValueDisplayTypes,
    ConditionSelectorEnums,
} from '../../../../utilities/Constants';

import { ConditionSelector } from '../../../../pages/query/ConditionSelector';
import { IAssertionDescriptorModel } from '../../../../interfaces/IAssertionDescriptor';
import { IQueryFilterCriteria } from '../../../../interfaces/IQuery';
import { IUserModel } from '../../../../interfaces/IUser';
import { IValidation } from '../../../../interfaces/IError';
import { TriggerFields } from './TriggerFields';
import { cloneDeep } from 'lodash';
import { useAssertionDescriptorsLookup } from '../../../../shared/react-query-hooks/useAssertionDescriptorsLookup';

export interface ITriggerFieldsSectionProps {
    availableFilterCriterias: IQueryFilterCriteria[];
    buildValueControls: (item: IQueryFilterCriteria) => JSX.Element;
    onConditionOptionChange: (item: IQueryFilterCriteria, itemIndex: number) => void;
    onSelectedTriggerFieldsChange: (selectedCriteria: IQueryFilterCriteria[]) => void;
    queryType: number;
    selectedTriggerFields: IQueryFilterCriteria[];
    user: IUserModel;
    validation: IValidation;
}

export function TriggerFieldsSection(props: ITriggerFieldsSectionProps) {
    const handleTriggerFieldsChange = (field: IQueryFilterCriteria[]) => {
        props.onSelectedTriggerFieldsChange(field);
    };

    const handleConditionOptionChange = (fields: IQueryFilterCriteria[], fieldIdx: number) => {
        props.onConditionOptionChange(fields[fieldIdx], fieldIdx);
    };

    const handleUpdateTriggerField = (toUpdate: IQueryFilterCriteria) => {
        const selectedTriggerFieldsCopy = cloneDeep(props.selectedTriggerFields);

        const existingIndex = selectedTriggerFieldsCopy.findIndex((field) => {
            if (field.isAssertionDescriptor) {
                return field.assertionDescriptorGuid === toUpdate.assertionDescriptorGuid;
            }
            return field.staticItemId === toUpdate.staticItemId;
        });

        selectedTriggerFieldsCopy.splice(existingIndex, 1, toUpdate);
        props.onSelectedTriggerFieldsChange(selectedTriggerFieldsCopy);
    };

    const handleDeleteTriggerField = (toDelete: IQueryFilterCriteria) => {
        const selectedTriggerFieldsCopy = cloneDeep(props.selectedTriggerFields);

        const existingIndex = selectedTriggerFieldsCopy.findIndex((field) => {
            if (field.isAssertionDescriptor) {
                return field.assertionDescriptorGuid === toDelete.assertionDescriptorGuid;
            }
            return field.staticItemId === toDelete.staticItemId;
        });

        selectedTriggerFieldsCopy.splice(existingIndex, 1);
        props.onSelectedTriggerFieldsChange(selectedTriggerFieldsCopy);
    };

    return (
        <>
            <TriggerFields
                onTriggerFieldSelected={handleTriggerFieldsChange}
                availableTriggerFields={props.availableFilterCriterias}
                selectedTriggerFields={props.selectedTriggerFields ?? []}
                validation={props.validation}
            />
            <TriggerFieldsValue
                onConditionOptionChange={handleConditionOptionChange}
                onDeleteField={handleDeleteTriggerField}
                onUpdateField={handleUpdateTriggerField}
                selectedTriggerFields={props.selectedTriggerFields}
                valuesColumn={props.buildValueControls}
            />
        </>
    );
}

interface ITriggerFieldsValueProps {
    valuesColumn: (item: IQueryFilterCriteria) => JSX.Element | JSX.Element[];
    onConditionOptionChange: (filters: IQueryFilterCriteria[], filterIdx: number) => void;
    onDeleteField: (item: IQueryFilterCriteria) => void;
    onUpdateField: (item: IQueryFilterCriteria) => void;
    selectedTriggerFields: IQueryFilterCriteria[];
}
function TriggerFieldsValue(props: ITriggerFieldsValueProps) {
    const { data: assertionDescriptors } = useAssertionDescriptorsLookup(
        AssertionDescriptorUsageTypesEnum.Case
    );

    return (
        <>
            {props.selectedTriggerFields.length > 0 && (
                <div className="row my-2">
                    <div className="col-sm-2" />
                    <div className="col-sm-10">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th className="col-sm-3">Trigger Field</th>
                                    <th className="col-sm-2">Condition</th>
                                    <th className="col-sm-7">Values</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {props.selectedTriggerFields.map((field, i) => (
                                    <TriggerFieldRow
                                        assertionDescriptors={assertionDescriptors ?? []}
                                        key={i}
                                        field={field}
                                        onConditionOptionChange={props.onConditionOptionChange}
                                        onDeleteClick={props.onDeleteField}
                                        onUpdateField={props.onUpdateField}
                                        selectedFields={props.selectedTriggerFields}
                                        valuesColumn={props.valuesColumn(field)}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
}

export interface ITriggerFieldRowProps {
    assertionDescriptors: IAssertionDescriptorModel[];
    field: IQueryFilterCriteria;
    onConditionOptionChange: (filters: IQueryFilterCriteria[], filterIdx: number) => void;
    onDeleteClick: (field: IQueryFilterCriteria) => void;
    onUpdateField: (field: IQueryFilterCriteria) => void;
    selectedFields: IQueryFilterCriteria[];
    valuesColumn: JSX.Element | JSX.Element[];
}

const conditionOpts = [
    {
        label: ConditionSelectorEnums.AllChanges,
        value: ConditionSelectorEnums.AllChanges,
    },
    {
        label: ConditionSelectorEnums.EqualTo,
        value: ConditionSelectorEnums.EqualTo,
    },
    {
        label: ConditionSelectorEnums.Range,
        value: ConditionSelectorEnums.Range,
    },
];

export function TriggerFieldRow(props: ITriggerFieldRowProps) {
    const {
        assertionDescriptors,
        field,
        onConditionOptionChange,
        onDeleteClick,
        selectedFields,
        valuesColumn,
    } = props;
    const assertionDescriptor = getSelectedAssertionDescriptor();

    function getSelectedAssertionDescriptor() {
        const assertionDescriptorMatch = assertionDescriptors.find((x) => {
            return (
                !!field.assertionDescriptorGuid &&
                !!x.guid &&
                x.guid.toUpperCase() === field.assertionDescriptorGuid.toUpperCase()
            );
        });
        return assertionDescriptorMatch;
    }

    const handleConditionOptionChange = (selectedItem: any) => {
        const triggerFieldSelected = [...selectedFields];

        const filterIdx = triggerFieldSelected.findIndex((f) => f.value === field.value) ?? -1;
        const filtersCopy = [...triggerFieldSelected];
        const filterItemToModify = filtersCopy[filterIdx];

        const conditionValue = selectedItem?.value;

        filterItemToModify.condition = conditionValue;
        filterItemToModify.selectedValues = [];
        filterItemToModify.text = '';
        filterItemToModify.startDate = undefined;
        filterItemToModify.endDate = undefined;
        filterItemToModify.booleanValue = undefined;
        filterItemToModify.numericValueFrom = undefined;
        filterItemToModify.numericValueFromText = '';
        filterItemToModify.numericValueTo = undefined;
        filterItemToModify.numericValueToText = '';
        filterItemToModify.validationError = '';
        filterItemToModify.dateRangeInterval = undefined;
        filterItemToModify.triggerAllChanges = conditionValue === ConditionSelectorEnums.AllChanges;

        onConditionOptionChange(filtersCopy, filterIdx);
    };

    let optionsToRender = [...conditionOpts];

    const isDateFieldSelected =
        assertionDescriptor?.valueDisplayType?.id ===
        AssertionDescriptorValueDisplayTypes.DateTime.Value;

    if (!isDateFieldSelected) {
        optionsToRender = optionsToRender.filter(
            (opt) => opt.value !== ConditionSelectorEnums.Range
        );
    }

    const handleDeleteClick = () => onDeleteClick(field);

    return (
        <tr>
            <td>{field.label}</td>
            <td style={{ paddingRight: 15 }}>
                <ConditionSelector
                    options={optionsToRender}
                    onChange={handleConditionOptionChange}
                    value={field.condition}
                />
            </td>
            <td style={{ paddingRight: '2em' }}>{valuesColumn}</td>
            <td width="5%" className="pt-1">
                {!field.readOnly && (
                    <button className="btn btn-gray btn-icon float-end" onClick={handleDeleteClick}>
                        <i className="fal fa-times color-white" />
                    </button>
                )}
            </td>
        </tr>
    );
}
